function convertMatrixToList(matrix: number[][]) {
  let adjList = new Array(matrix.length);
  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length; j++) {
      if (matrix[i][j] !== 0) {
        if (!adjList[i]) {
          adjList[i] = [];
        }
        adjList[i].push(j);
      }
    }
  }
  return adjList;
}

function convertListToMatrix(list: number[][]) {
  let adjMatrix = new Array(list.length);
  for (let r = 0, n = list.length; r < n; r++) {
    adjMatrix[r] = new Array(list.length).fill(0);
    for (let c of list[r]) {
      adjMatrix[r][c] = 1;
    }
  }
  return adjMatrix;
}

const defaults = {
  adjacencyMatrix: [],
  adjacencyList: [],
};

interface constructorOpt {
  adjacencyMatrix?: number[][];
  adjacencyList?: number[][];
}

export class Graph {
  numberNodes: number;
  adjacencyMatrix: number[][];
  adjacencyList: number[][];

  constructor(options: constructorOpt) {
    this.adjacencyMatrix = options.adjacencyMatrix || defaults.adjacencyMatrix;
    this.adjacencyList = options.adjacencyList || defaults.adjacencyList;
    // when adjacencyMatrix is missing
    if (this.adjacencyMatrix.length === 0) {
      this.numberNodes = this.adjacencyList.length;
      this.adjacencyMatrix = convertListToMatrix(this.adjacencyList);
    }
    // when adjacencyList is missing
    else {
      this.numberNodes = this.adjacencyMatrix.length;
      this.adjacencyList = convertMatrixToList(this.adjacencyMatrix);
    }
  }

  addEdge(node1: number, node2: number) {
    if (node1 >= this.numberNodes) {
      console.log("node1 does not exist");
      return;
    }
    if (node2 >= this.numberNodes) {
      console.log("node2 does not exist");
      return;
    }
    this.adjacencyMatrix[node1][node2] = 1;
  }

  getNeighbors(node: number) {
    return this.adjacencyList[node];
  }

  hasEdge(node1: number, node2: number) {
    if (
      node1 >= 0 &&
      node1 < this.numberNodes &&
      node2 >= 0 &&
      node2 < this.numberNodes
    ) {
      return this.adjacencyMatrix[node1][node2] === 1;
    }

    return false;
  }

  removeEdge(node1: number, node2: number) {
    if (
      node1 >= 0 &&
      node1 < this.numberNodes &&
      node2 >= 0 &&
      node2 < this.numberNodes
    ) {
      this.adjacencyMatrix[node1][node2] = 0;
    }
  }
}
