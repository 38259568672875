import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TokenAuth{
  loginNick?:string;
  role?:string;
  isLoggedIn:boolean;
}

export const adminSlice = createSlice({
  name : "admin",
  initialState: {isLoggedIn:false},
  reducers: {
    setToken : (state:TokenAuth, action:PayloadAction<TokenAuth>) =>{
      state = action.payload
      return state;
    }
  }
}) 

export default adminSlice.reducer;
