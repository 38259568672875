import React from "react";

// interface propsHTML{
//   contentHTML: string;
// }

const CustomMsg = (props) =>{
  console.log(props)
  return (
    <div><a href="/test">test</a></div>
  );
}

export default CustomMsg;