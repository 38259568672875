import { DialogNode } from "../model";
import { Constants } from "../components/chatbot_kit/dialog_flowchart/Content";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// const init_nodeContent : NodeCont = new NodeCont(Constants.NODES, Constants.ADJACENCYLIST);
const init_nodeContent : {dialog: DialogNode[], order :number[][] } = {dialog: Constants.NODES, order :Constants.ADJACENCYLIST};

export const nodesSlice = createSlice({
  name : "nodeSlice",
  initialState : init_nodeContent,
  reducers : {
    load : (state, action: PayloadAction<{dialog: DialogNode[], order :number[][]}>) =>{
      state = action.payload;
      return state;
    },
    restart : () => {
      return {dialog: Constants.NODES, order :Constants.ADJACENCYLIST};
    }
  }
})

export default nodesSlice.reducer;