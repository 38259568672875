import "./NodeEdit.css";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import axios from "axios";
import { SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Constants } from "../../Constants";
import { IchatBotNodeDB } from "../../model";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const NodeEditor = () => {
  const param = useParams();
  const [nodeid, setNodeID] = useState(0);
  const [nodeTitle, setNodeTitle] = useState("");
  const [nodeContent, setNodeContent] = useState("");
  const [adjnode, setAdjNode] = useState("");

  useEffect(() => {
    axios
      .get<IchatBotNodeDB>(Constants.CHATBOT_GET_NODE + "?id=" + param.id)
      .then((res) => {
        setNodeID(res.data.nodeid);
        setNodeTitle(res.data.nodetitle);
        setNodeContent(res.data.nodecontent);
        setAdjNode(res.data.adjnode);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [param.id]);

  const onEditHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    const input = window.confirm("Pls make a backup of your changes. Do you want to confirm the changes?");
    if (input){
      try {
        const result = await axios.put(Constants.URL_ADMIN_SETCHATBOTNODE, {
          nodeid: nodeid,
          nodetitle: nodeTitle,
          nodecontent: nodeContent,
          adjnode: adjnode,
        });
        window.alert("Node edited");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="NodeEditContainer">
      <div className="headerTitle">
        <h3>Chatbot Node Editor for Nodes no {param.id}</h3>
      </div>
      <form onSubmit={onEditHandler}>
        <label htmlFor="nodetitle">Node Title: </label>
        <input
          type="text"
          value={nodeTitle}
          id="nodetitle"
          onChange={(e) => setNodeTitle(e.target.value)}
        />
        <br />
        <label htmlFor="nodecontent">Node Content: </label>
        <Textarea
          minRows={2}
          placeholder="Minimum 1 rows"
          value={nodeContent}
          id="nodecontent"
          onChange={(e) => setNodeContent(e.target.value)}
        />
        <br />
        <label htmlFor="adjlist">Adjacent Nodes (Format: 1,2,3,4)</label>
        <input
          type="text"
          value={adjnode}
          id="adjlist"
          onChange={(e) => setAdjNode(e.target.value)}
        />
        <br />

        <button type="submit">Edit</button>
      </form>
    </div>
  );
};

export default NodeEditor;
