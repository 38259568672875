import { SyntheticEvent, useState } from "react";
import { useNavigation } from "react-router-dom";
import { TokenAuth } from "../../../store/admin";
import { RootState } from "../../../store/indexStore";
import axios, { AxiosError } from "axios";
import { Constants } from "../../../Constants";
import { useSelector } from "react-redux";

// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const CreateUser = () => {
  // create user form
  const navigation = useNavigation();
  const [userName, setUserName] = useState("");
  const [pw, setPw] = useState("");
  const [adminSelect, setAdminSelect] = useState("admin");

  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  const handleCreateUser = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      const response = await axios.post<{ result: string }>(
        Constants.URL_ADMINCREATE,
        {
          email: userName,
          pw: pw,
          role: adminSelect,
        }
      );
      alert(response.data.result);
      window.location.reload();
    } catch (error) {
      console.log("create user error", error);
      if (error instanceof AxiosError) {
        alert(`error creating user:  ${error.response?.data.result}`);
      }
    }
  };


  return (
    <div className="usercontrol">
      {loggedStatus.role === "super_admin" && (
        <form onSubmit={handleCreateUser}>
          <h3>Create Admin (Only for Super Admin)</h3>
          <label htmlFor="username" style={{ display: "block" }}>
            New Username
          </label>
          <input
            id="username"
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            size={30}
            minLength={3}
            required
          />

          <label htmlFor="pw" style={{ display: "block" }}>
            Password
          </label>
          <input
            id="pw"
            type="text"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            size={30}
            minLength={6}
            required
          />
          <label htmlFor="roles" style={{ display: "block" }}>
            Role:
          </label>
          <select
            name="roles"
            id="roles"
            value={adminSelect}
            onChange={(e) => setAdminSelect(e.target.value)}
          >
            <option value="admin">admin</option>
            <option value="super_admin">super_admin</option>
          </select>
          <div className="button_submit">
            <button type="submit">
              {navigation.state === "submitting" ? "Creating" : "Create"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateUser;
