import { Outlet, useParams } from "react-router-dom";
import Header from "../components/header/Header";
import { Constants } from "../Constants";
import BottomTab from "../components/bottom_tab/BottomTab";
import "./EduRoot.css";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";

const RootLayout = () => {
  const param = useParams();
  // console.log(param.var);
  const contents = useSelector((state:RootState) => state.eduContent);

  const content = contents.find( x => {
    const splitArray = x.path_var.split("/");
    return param.var===splitArray[splitArray.length-1];
  } )


  if (typeof param.var === "undefined") {
    return (
      <>
        <Header htitle={Constants.HEADER_TOOTHBUDDY} />
        <Outlet />
        <BottomTab />
      </>
    );
  } else if (content) {
    return (
      <>
        <Header
          // htitle={content.title || Constants.DEFAULT_HEADER_TITLE}
          htitle={Constants.HEADER_TOOTHBUDDY}
        />
        <Outlet />
        <BottomTab />
      </>
    );
  }
  return (
    <>
      <Header htitle="Not Found" />
      <Outlet />
      <BottomTab />
    </>
  );
};

export default RootLayout;
