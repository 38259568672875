import "./header.css";
import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Toolbar, Container, AppBar } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Constants } from "../../Constants";
import Dropdown from "react-multilevel-dropdown";
import MenuDropDown from "./MenuDropDown";
import { useState } from "react";

const HeaderBar: React.FC<{ htitle: string }> = (props) => {
  // const defaultHeaderTitle = Constants.DEFAULT_HEADER_TITLE
  // const ToothBuddy = Constants.TOOTHBUDDY
  const param = useParams();
  // console.log(param);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const onBackHandler = () => {
    navigate(-1);
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white", flexGrow: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {(param.var || props.htitle === Constants.HEADER_NOT_FOUND) && (
            <IconButton onClick={onBackHandler}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <h1 className="headerH1">{props.htitle}</h1>
          <div className="menuButton">
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2, color: "#949286" }}
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>
            {open && <MenuDropDown menuStatus={setOpen} />}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderBar;
