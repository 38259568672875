import React, { useState } from "react";
import { Button } from "@mui/material";
import { Constants as GConstants } from "../dialog_flowchart/Content";

const RootWidget = (props) => {
  // console.log("this is props before click:   ", props);
  const graph = GConstants.GRAPH;

  const [adjNodes, setAdjNodes] = useState(graph.getNeighbors(props.node));
  const [clicked,setClicked] = useState(false);
  // console.log("adjNodes:  ", graph.getNeighbors(props.node));
  const clickhandler = (node) => {
    props.actions.clientSelectionHandler(GConstants.NODES[node].title, node);
    if (!clicked) setAdjNodes(graph.getNeighbors(props.node));
    setClicked(true)
    // setAdjNodes([])
    // props = { ...props, node };
    // console.log("props.node:   ", props.node);
    // console.log("AdjNodes:   ", graph.getNeighbors(node));
    // setAdjNodes(graph.getNeighbors(node))
    // console.log("prop from root:  ", props);
    // setAdjNodes(graph.getNeighbors(props.node))
  };

  return (
    <div className="rootWidget">
      {adjNodes.map((node) => {
        return (
          <Button
            variant="outlined"
            sx={{ borderRadius: 28, cursor: "pointer" }}
            onClick={() => clickhandler(node)}
            key={node}
          >
            {GConstants.NODES[node].title}
          </Button>
        );
      })}
    </div>
  );
};

export default RootWidget;
