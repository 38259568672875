import { createChatBotMessage } from 'react-chatbot-kit';
import { Constants } from '../../../Constants';
import Avatar from '../components/Avatar';
import StartBtn from '../components/StartBtn';
// import IConfig from 'react-chatbot-kit/build/src/interfaces/IConfig';
import CustomMsg from '../components/CustomMsg';
import RootWidget from '../components/RootWidget';
import CustomBotMessage from '../components/CustomBotMessage';


const config = {
  initialMessages: [
    createChatBotMessage(Constants.INITIAL_MSG+"<br/><br/>"+Constants.TB_DISCLAIMER, {widget:"DentalSelfHelp" }),
  ],
  botName: Constants.TOOTHBUDDY,
  customComponents: {
    botAvatar : (props) => <Avatar {...props}/>,
    botChatMessage: (props) => <CustomBotMessage {...props} delay={Constants.CHATBOT_REPLY_DELAY} loading={true}/>,
  },
  state:{
    node : 5
  },
  widgets:[
    {
      widgetName: "DentalSelfHelp",
      widgetFunc: (props) => <StartBtn {...props} bName="Dental Self Help"/>,
      mapStateToProps: [],
    },
    {
      widgetName: "RootWidget",
      widgetFunc: (props) => <RootWidget {...props} />,
      mapStateToProps:["node"],
    },
  ],
  customMessages:{
    c1 : (prop) => <CustomMsg {...prop} />
  }
  
};

export default config;