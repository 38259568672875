import axios from "axios";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Constants } from "../../../Constants";
import { Constants as ContentConstant } from "../../chatbot_kit/dialog_flowchart/Content";
import { IChatlog } from "../../../model";
import "./GetFilteredConversation.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { debounce } from "lodash";

// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
type ObjectKey = string | number | symbol;
const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
  items: TItem[],
  key: K
): Record<ObjectKey, TItem[]> =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {} as Record<ObjectKey, TItem[]>
  );

interface IChatlogRemark extends IChatlog {
  remark: string;
}

const GetConversation = () => {
  const [chatlog, setChatlog] = useState<IChatlogRemark[]>([]);
  const [grouped, setGrouped] = useState<Record<ObjectKey, IChatlogRemark[]>>(
    {}
  );
  const [filteredGrp, setFilteredGrp] = useState("none");
  const [pgNumber, setPgNumber] = useState<number>(1);
  const [historyLimit, setHistoryLimit] = useState<number | string>(2000);
  const [pg, setPg] = useState(1);
  const [rowNo, setRowNo] = useState(20);
  // const re = /nodes\.\d+/;
  const re = /label\.\w+/;

  const pgNo = (
    historyLimit: number | string,
    filteredGrp: string,
    rowNo: number
  ) => {
    const count = chatlog
      .slice(0, +historyLimit)
      .filter((row) => row.remark === filteredGrp).length;

    if (count % rowNo === 0)
      setPgNumber(isNaN(count / rowNo) ? 1 : count / rowNo);
    else setPgNumber(Math.ceil(isNaN(count / rowNo) ? 1 : count / rowNo));
  };

  const getChatlogRemark = (totalrow: number | string) => {
    axios
      .get<IChatlog[]>(Constants.URL_ADMINGETALLCHATLOG + "?limit=" + totalrow)
      .then((res) => {
        const chatlogRemarks: IChatlogRemark[] = [];
        // convert string datetime from db to data obj
        res.data.forEach((row) => {
          row.datetime_entered = new Date(
            row.datetime_entered
          ).toLocaleString();
          // create remark by using regex to test if there is "nodes" word present
          // const remark = re.test(row.botreply)
          //   ? ContentConstant.NODES[
          //       parseInt(
          //         row.botreply
          //           .match(re)
          //           ?.find((x) => x)
          //           ?.split(".")[1] || "0"
          //       )
          //     ].title
          //   : "none";
          const remark = re.test(row.botreply)
            ? row.botreply.substring(0, 11) === "label.Other"
              ? "label.Other"
              : row.botreply
            : "none";
          chatlogRemarks.push({
            ...row,
            remark,
          });
        });
        setChatlog(chatlogRemarks);
        // group the chatlogRemarks[] using obj key of remark
        let grouped = groupBy(chatlogRemarks, "remark");
        setGrouped(grouped);

        // update pagination
        const count = chatlogRemarks
          .slice(0, isNaN(+historyLimit) ? 2000 : +historyLimit)
          .filter((row) => row.remark === filteredGrp).length;

        if (count % rowNo === 0)
          setPgNumber(isNaN(count / rowNo) ? 1 : count / rowNo);
        else setPgNumber(Math.ceil(isNaN(count / rowNo) ? 1 : count / rowNo));
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  const debounceLoad = useCallback(
    debounce((pgLimit: number) => {
      getChatlogRemark(pgLimit);
    }, 800),
    []
  );

  useEffect(() => {
    // get first {historyLimit} from db
    getChatlogRemark(historyLimit);
  }, []);

  const historyLimitHandler = (e: ChangeEvent<HTMLInputElement>) => {
    debounceLoad(isNaN(+e.target.value) ? 2000 : +e.target.value);
    setPg(1);
    setHistoryLimit(+e.target.value);
    pgNo(+e.target.value, filteredGrp, rowNo);
  };

  const rowNoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPg(1);
    setRowNo(+e.target.value === 0 ? 1 : +e.target.value);
    pgNo(
      historyLimit,
      filteredGrp,
      +e.target.value === 0 ? 1 : +e.target.value
    );
  };

  const selectGrpHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setPg(1);
    setFilteredGrp(e.target.value);
    pgNo(historyLimit, e.target.value, rowNo);
  };

  const paginationHandler = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPg(page);
    pgNo(historyLimit, filteredGrp, rowNo);
  };

  return (
    <>
      <h3>
        ChatBot Filtered ChatLog from Latest {historyLimit} or less chat history
      </h3>
      <div className="history">
        <label htmlFor="rowno">History limit: </label>
        <input
          id="rowno"
          type="number"
          value={historyLimit}
          min={0}
          onChange={historyLimitHandler}
          required
        />
      </div>
      <div className="rowNo">
        <label htmlFor="rowno">Number of Row Per Page: </label>
        <input
          id="rowno"
          type="number"
          value={rowNo}
          onChange={rowNoHandler}
          required
        />
      </div>
      <div className="selectCount">
        <select
          name="group"
          id="group"
          className="filteredChatlog"
          value={filteredGrp}
          onChange={selectGrpHandler}
        >
          {Object.keys(grouped)
            .sort((a, b) => grouped[b].length - grouped[a].length)
            .map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
        </select>
        <span> Count: {grouped[filteredGrp]?.length} </span>
      </div>
      <table className="filteredChatlog">
        <thead>
          <tr>
            <th>datetime</th>
            <th>user input</th>
            <th>bot reply</th>
            <th>Node Definition</th>
          </tr>
        </thead>
        <tbody>
          {chatlog
            .slice(0, +historyLimit)
            .filter((row) => row.remark === filteredGrp)
            .slice((pg - 1) * rowNo, pg * rowNo)
            .map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.datetime_entered}</td>
                  <td>{row.userinput}</td>
                  <td>{row.botreply}</td>
                  <td>{row.remark}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Stack spacing={2}>
        <Pagination
          count={pgNumber}
          variant="outlined"
          shape="rounded"
          page={pg}
          onChange={paginationHandler}
        />
      </Stack>
    </>
  );
};

export default GetConversation;
