import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./ChatBotkit.css";

import config from "../logic/config.jsx";
import MessageParser from "../logic/MessageParser";
import ActionProvider from "../logic/ActionProvider";

const ChatBotKit = () => {
  return (
    <div className="kitcontainer">
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        validator={ (msg) => msg.length!==0}
      />
    </div>
  );
};

export default ChatBotKit;
