import "./header.css";
import * as React from "react";
import { IconButton, Toolbar, Container, AppBar, Button } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { RootState } from "../../store/indexStore";
import { TokenAuth } from "../../store/admin";
import { useState } from "react";
import MenuDrop from "./MenuDrop";

const HeaderBar: React.FC<{ htitle: string }> = (props) => {
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );
  const [open,setOpen]=useState(false);

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white", flexGrow: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <h1 className="headerH1">{props.htitle}</h1>
          {loggedStatus.isLoggedIn && (
            <Link to="/tbadmin/dashboard">
              <Button variant="text">DashBoard</Button>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/tbadmin/dialogmap">
              <Button variant="text">ChatBotDialogNode</Button>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/tbadmin/post">
              <Button variant="text">Post</Button>
            </Link>
          )}
          <span className="spacerAdminHeader"> </span>
          <div className="menuButtonAdmin">
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2, color: "#949286" }}
              onClick={()=>setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>
            {open && <MenuDrop menuStatus = {setOpen}/>}
          </div>
          {loggedStatus.isLoggedIn && (
            <span className="admininfo">
              {loggedStatus.loginNick}
              <br />
              {loggedStatus.role}
            </span>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to={"/tbadmin/logout"}>
              <Button variant="contained">Logout</Button>
            </Link>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderBar;
