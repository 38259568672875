import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from "react-router-dom";
import RootLayout from "./pages/EduRoot";
import ErrorPage from "./pages/errorPage";
import Home from "./pages/Home";
import EduTemplate from "./components/edu_content/EduTemplate";
import ChatBotRoot from "./pages/ChatBotRoot";
import ChatBot from "./components/chatbot/ChatBot";
import ChatBotKit from "./components/chatbot_kit/components/ChatBot";
import store from "./store/indexStore";
import { Provider } from "react-redux";
import axios, { AxiosError } from "axios";
import { Constants } from "./Constants";
import { eduSlice } from "./store/eduContent";
import WebFont from "webfontloader";
import AdminRoot from "./pages/AdminRoot";
import Login from "./components/admin/Login";
import DashBoard from "./components/admin/DashBoard";
import {
  verification,
  loginAuthChecker,
} from "./components/admin/util/authHelper";
import LogOut from "./components/admin/LogOutPage";
import DialogMap from "./components/admin/DialogMap";
import PostPg from "./components/admin/PostPg";
import PostEdit from "./components/admin/PostEdit";
import { nodesSlice } from "./store/nodesContent";
import { DialogNode, IchatBotNodeDB } from "./model";
import NodeEditor from "./components/admin/NodeEdit";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createHashRouter([
  {
    path: "/chatbot",
    element: <ChatBotRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "1",
        element: <ChatBot />,
      },
      {
        path: "2",
        element: <ChatBotKit />,
      },
    ],
  },
  {
    path: "/tbadmin",
    element: <AdminRoot />,
    children: [
      {
        path: "login",
        element: <Login />,
        loader: loginAuthChecker,
      },
      {
        path: "logout",
        element: <LogOut />,
      },
      {
        path: "dashboard",
        element: <DashBoard />,
        loader: verification,
      },
      {
        path: "dialogmap",
        element: <DialogMap />,
        loader: verification,
      },
      // {
      //   path: "post",
      //   element: <PostPg />,
      //   loader: verification,
      // },
      {
        path: "post",
        loader: verification,
        children: [
          {
            path: ":var",
            element: <PostEdit />,
          },
          {
            path: "",
            element: <PostPg />,
            loader: verification,
          },
        ],
      },
      {
        path: "nodes/:id",
        element: <NodeEditor/>,
        loader:verification,
      },
      {
        path: "",
        element: <Login />,
        loader: loginAuthChecker,
      },
    ],
  },
  {
    path: "/education",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":var",
        element: <EduTemplate />,
      },
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
]);

WebFont.load({
  google: {
    families: ["DM Sans", "sans-serif"],
  },
});

export const getContents = async () => {
  try {
    const res = await axios.get(Constants.EDU_CONTENT, {
      timeout: 10000,
      timeoutErrorMessage: "timeout 10 second",
    });
    store.dispatch(eduSlice.actions.loadContent(res.data));
    // console.log(store);
  } catch (error: unknown) {
    console.log(error as AxiosError);
  }
};

export const getChatBotNodes = async () => {
  try {
    const res = await axios.get<IchatBotNodeDB[]>(Constants.CHATBOT_GET_NODES, {
      timeout: 10000,
      timeoutErrorMessage: "timeout 10 second",
    });
    const l = res.data.length;
    const nodeDB: DialogNode[] = [];
    const adjList: number[][] = [];
    for (let i = 0; i < l; i++) {
      nodeDB.push({ title: "", content: "" });
      adjList.push([]);
    }
    res.data.forEach((n) => {
      nodeDB[n.nodeid].title = n.nodetitle;
      nodeDB[n.nodeid].content = n.nodecontent;
      if (n.adjnode.length > 0)
        adjList[n.nodeid] = n.adjnode.split(",").map((x) => parseInt(x));
    });
    store.dispatch(nodesSlice.actions.load({ dialog: nodeDB, order: adjList }));
  } catch (error: unknown) {
    console.log(error as AxiosError);
  }
};

getContents();
getChatBotNodes();

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
