import PostCreator from "./components/PostCreator";
import PostList from "./components/PostList";
import "./PostPg.css";
import React, { useState } from "react";

const PostPg = () => {
  const [create, setCreate] = useState(false);
  const getPglimitChild = (data: number) => {
    // console.log(data);
  };
  const iscreate = () => {
    setCreate((prev) => !prev);
  };

  return (
    <div className="PostPgContainer">
      <PostList getlmt={getPglimitChild} create={create} />
      <PostCreator onCreate={iscreate} />
    </div>
  );
};

export default PostPg;
