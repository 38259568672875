import React, { useEffect, useState } from "react";
import ConditionallyRender from "react-conditionally-render";
import parse, { HTMLReactParserOptions } from "html-react-parser";
// import ChatbotMessageAvatar from "react-chatbot-kit/src/components/ChatbotMessage/ChatBotMessageAvatar/ChatbotMessageAvatar";
import Loader from "./Loader";

// import "./CustomBotMessage.css";
// import { callIfExists } from "react-chatbot-kit/src/components/Chat/chatUtils";
import {
  ICustomComponents,
  ICustomStyles,
} from "react-chatbot-kit/src/interfaces/IConfig";
import { Link } from "react-router-dom";

interface IChatbotMessageProps {
  message: string;
  withAvatar?: boolean;
  loading?: boolean;
  messages: any[];
  delay?: number;
  id: number;
  setState?: React.Dispatch<React.SetStateAction<any>>;
  customComponents?: ICustomComponents;
  customStyles: { backgroundColor: string };
}
const ChatbotMessage = ({
  message,
  withAvatar = true,
  loading,
  messages,
  customComponents,
  setState,
  customStyles,
  delay,
  id,
}: IChatbotMessageProps) => {
  const [show, toggleShow] = useState(false);
  useEffect(() => {
    let timeoutId: any;
    const disableLoading = (
      messages: any[],
      setState: React.Dispatch<React.SetStateAction<any>> | any
    ) => {
      let defaultDisableTime = 750;
      if (delay) defaultDisableTime += delay;

      timeoutId = setTimeout(() => {
        messages = messages === undefined ? [] : messages;
        const newMessages = [...messages];
        const message = newMessages.find((message) => message.id === id);

        if (!message) return;
        message.loading = false;
        message.delay = undefined;

        setState((state: any) => {
          const freshMessages = state.messages;
          const messageIdx = freshMessages.findIndex(
            (message: any) => message.id === id
          );
          freshMessages[messageIdx] = message;

          return { ...state, messages: freshMessages };
        });
      }, defaultDisableTime);
    };

    disableLoading(messages, setState);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay, id]);

  useEffect(() => {
    if (delay) {
      setTimeout(() => toggleShow(true), delay);
    } else {
      toggleShow(true);
    }
  }, [delay]);

  const chatBoxCustomStyles = { backgroundColor: "" };
  const arrowCustomStyles = { borderRightColor: "" };

  if (customStyles) {
    chatBoxCustomStyles.backgroundColor = customStyles.backgroundColor;
    arrowCustomStyles.borderRightColor = customStyles.backgroundColor;
  }

  // use for replacing <a> to Link so that request is sent to client instead of server
  // somehow instanceof Element always return false, so have to set it domNode to any for it to work
  const options: HTMLReactParserOptions = {
    replace(domNode:any ) {
      if (domNode.name === 'a') {
        return <Link to={domNode.attribs.href} target="_blank">{domNode.children[0].data}</Link>;
      }
    },
  };


  return (
    <div
      className="react-chatbot-kit-chat-bot-message"
      style={chatBoxCustomStyles}
    >
      {/* <span dangerouslySetInnerHTML={{ __html: message }} /> */}
      {/* {parse(message,options)} */}
      {!show? <Loader/>: parse(message,options)}
      <ConditionallyRender
        condition={withAvatar}
        show={
          <div
            className="react-chatbot-kit-chat-bot-message-arrow"
            style={arrowCustomStyles}
          ></div>
        }
      />
    </div>
  );
};

export default ChatbotMessage;
