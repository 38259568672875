import React from "react";

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    // console.log(`msg received = ${message}`);
    // console.log("children: ", children);
    // console.log("actions:  ", actions)
    // for testing purpose
    // actions.echoHandler(message);
    if (message.length >= 2) {
      // console.log("send to backend server");
      // actions.testvalidInputHandler(message);
      // actions.validInputHandler(message);
      actions.validInputNlpJSHandler(message);
    } else {
      actions.invalidInputHandler();
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
