import { useRouteError } from "react-router-dom";
import "./errorPage.css";
import BottomTab from "../components/bottom_tab/BottomTab";
import Header from "../components/header/Header";

export default function ErrorPage() {
  const error: unknown = useRouteError();
  console.error(error);

  return (
    <>
      <Header htitle="Not Found" />
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>
            {(error as Error)?.message ||
              (error as { statusText?: string })?.statusText}
          </i>
        </p>
      </div>
      <BottomTab />
    </>
  );
}
