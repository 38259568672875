import React from "react";
import { Constants } from "../../../Constants";
import { createClientMessage } from "react-chatbot-kit";
import { Constants as GConstants } from "../dialog_flowchart/Content";
import store from "../../../store/indexStore";
import { chatbotSlice } from "../../../store/chatbot";
import { Graph } from "../dialog_flowchart/Graph";

const BOT_DEFAULTMSG =
  "Sorry. I do not understand your request. You can try using the dental self help button at the top of the chat window";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const updateMsg = (msg) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, msg],
    }));
  };

  const echoHandler = (userTxt) => {
    const botReply = createChatBotMessage(userTxt);
    updateMsg(botReply);
  };

  const nodeHandler = (node) => {
    // console.log("nodeHandler ",node)
    // const graph = GConstants.GRAPH;
    const graph = new Graph({adjacencyList: store.getState().node.order});
    const dialog = store.getState().node.dialog;
    let botReply = createChatBotMessage();
    const adjnodes = graph.getNeighbors(node);
    if (adjnodes.length > 1) {
      botReply = createChatBotMessage(dialog[node].content, {
        widget: "RootWidget",
      });
    } else {
      // console.log("end stage reached, ", adjnodes);
      setState((prev) => ({
        ...prev,
        adjnodes,
      }));
      // use redux to set modal to be on
      store.dispatch(
        chatbotSlice.actions.setModal({
          node: adjnodes[0] === undefined ? node : adjnodes[0],
          content: dialog[adjnodes[0] === undefined ? node : adjnodes[0]].content,
          modalOn: true,
        })
      );
      if (dialog[adjnodes[0]] !== undefined) {
        botReply = createChatBotMessage(dialog[adjnodes[0]].content);
      } else {
        botReply = createChatBotMessage(dialog[node].content);
      }
    }
    updateMsg(botReply);
  };

  const clientSelectionHandler = (userTxt, node) => {
    const forceUserReply = createClientMessage(userTxt);
    updateMsg(forceUserReply);
    setState((prev) => ({
      ...prev,
      node,
    }));
    nodeHandler(node);
  };

  const invalidInputHandler = () => {
    const botReply = createChatBotMessage("invalid");
    updateMsg(botReply);
  };

  // testing handler for sending post request to backend server
  const testvalidInputHandler = async (userTxt) => {
    console.log("childen: ", children);
    console.log("userTxt: ", userTxt);
    console.log("msg history", children.props.children.props.state.messages);
    try {
      const response = await fetch(Constants.TEST_API, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userTxt,
          history: children.props.children.props.state.messages,
        }),
      });
      const data = await response.json();
      console.log(data);
      const botReply = createChatBotMessage(data.msg);
      updateMsg(botReply);
    } catch (error) {
      console.log("error: ", error);
      const botReply = createChatBotMessage(
        Constants.TB_AWAYMSG
        // "Sorry. I am away for a short break and will be right back shortly. You can try using the Dental Self help button at the top."
      );
      updateMsg(botReply);
    }
  };

  // actual chatbot handler for sending to backend server
  const validInputHandler = async (userTxt) => {
    // console.log("childen: ", children);
    // console.log("userTxt: ", userTxt);
    // console.log("msg history", children.props.children.props.state.messages);
    try {
      const response = await fetch(Constants.CHATBOT_API, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userTxt,
          history: children.props.children.props.state.messages,
        }),
      });
      const data = await response.json();
      console.log("data: ", data);
      if (response.status > 299)
        throw new Error(`${response.status} = ${response.statusText}`);

      const botReply = createChatBotMessage(data.res);
      updateMsg(botReply);
    } catch (error) {
      console.log("errorObj: ", error);
      const botReply = createChatBotMessage(Constants.TB_AWAYMSG);
      updateMsg(botReply);
    }
  };

  // nlp handler
  const validInputNlpJSHandler = async (userTxt) => {
    // console.log("childen: ", children);
    // console.log("userTxt: ", userTxt);
    // console.log("msg history", children.props.children.props.state.messages);
    try {
      const response = await fetch(Constants.CHATBOT_NLPJS_API, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userTxt,
          history: children.props.children.props.state.messages,
        }),
      });
      const data = await response.json();
      // console.log("data: ", data);
      if (response.status > 299)
        throw new Error(`${response.status} = ${response.statusText}`);
      // display node button when there is a node reply
      const botReply = createChatBotMessage(data.reply);
      if (GConstants.LINKS.has(data.reply)) {
        const mappedReply = GConstants.LINKS.get(data.reply);
        if (mappedReply.startsWith("nodes.")) {
          const node = Number.parseInt(mappedReply.substring(6));
          // console.log("node called   ", node);
          setState((prev) => ({
            ...prev,
            node,
          }));
          nodeHandler(node);
        }
        else if (data.reply==="label.NotDentalQn" ){
          updateMsg(createChatBotMessage(mappedReply));
        }
        // if there is no node reply, return modal when it is not away or defaultmsg when bot does not have answer
        else {
          // use redux to set modal to be on
          store.dispatch(
            chatbotSlice.actions.setModal({
              node: 5,
              content: mappedReply,
              modalOn: true,
            })
          );
          updateMsg(createChatBotMessage(mappedReply));
        }
      } else updateMsg(botReply);
    } catch (error) {
      console.log("errorObj: ", error);
      const botReply = createChatBotMessage(Constants.TB_AWAYMSG);
      updateMsg(botReply);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            echoHandler,
            clientSelectionHandler,
            validInputHandler,
            testvalidInputHandler,
            invalidInputHandler,
            validInputNlpJSHandler,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
