import { EduCont } from "./EduCont";
import { EduContentDB } from "./model";

export class Constants {
  static readonly DEFAULT_HEADER_TITLE = "Education";
  static readonly HEADER_TOOTHBUDDY = "ToothBuddy";
  static readonly HEADER_NOT_FOUND = "Not Found";
  static readonly TOOTHBUDDY = "Tooth Buddy";

  static readonly URL_ADMINLOGIN = "/tbadmin/login";
  static readonly URL_ADMINVERIFY = "/tbadmin/verification";
  static readonly URL_ADMINCREATE = "/tbadmin/newadmin";
  static readonly URL_ADMINGETALL = "/tbadmin/alladmins";
  static readonly URL_ADMINGETALLCHATLOG = "/tbadmin/allchatlog";
  static readonly URL_ADMINGETCHATLOGCOUNT = "/tbadmin/chatlogcount";
  static readonly URL_ADMINCHANGEPW = "/tbadmin/changepw";
  static readonly URL_ADMINDELETE = "/tbadmin/deleteadmin";
  static readonly URL_ADMINTOGGLEDISPLAY = "/tbadmin/educontentdisplay";
  static readonly URL_ADMIN_CREATEPOST = "/tbadmin/educontent";
  static readonly URL_ADMIN_SETCHATBOTNODE = "/tbadmin/nodes";

  static readonly ADMIN_SUPER = "super_admin";

  static readonly INITIAL_MSG = `Welcome to ${this.TOOTHBUDDY}!<br/>
I am here to help! What can I do for you today?`;
  static readonly TB_AWAYMSG =
    "Sorry. I am away for a short break and will be right back shortly. Please try again later. Alternatively, you can try out the Dental Self help button at the top.";
  static readonly TB_DISCLAIMER =
    "Do note that the chatbot is not a substitute for professional dental advice and diagnosis. If you have any doubts, please consult a medical/dental professional. If you are in severe pain or have a dental emergency, please visit the A&E department at your nearest hospital.";

  static readonly TEST_API = "/api/aichat/allmsg";
  static readonly CHATBOT_API = "/api/aichat/userinputp";
  static readonly CHATBOT_GET_NODES = "/api/chatbotnodes/allnodes";
  static readonly CHATBOT_GET_NODE = "/api/chatbotnodes/nodes";
  static readonly CHATBOT_NLPJS_API = "/api/nlpchat/userinput";
  static readonly CHATBOT_REPLY_DELAY = 300;
  static readonly EDU_CONTENT = "/api/education/alleducontents";
  static readonly EDU_CONTENT_HEADER = "/api/education/alleduheaders";

  static DEFAULT_EDU_CONTENT: EduContentDB[] = [
    {
      title: "Tooth Brushing",
      pic_url: "/dental_icons/brushing.png",
      path_var: "/education/brushing",
      content_html: EduCont.BRUSHING,
      head_title: "Oral Health Wellness",
      eduheader: 1,
      display: true,
    },
    {
      title: "Flossing",
      pic_url: "/dental_icons/flossing.png",
      path_var: "/education/flossing",
      content_html: EduCont.FLOSSING,
      head_title: "Oral Health Wellness",
      eduheader: 1,
      display: true,
    },
    {
      title: "Fluorides",
      pic_url: "/dental_icons/fluorides.png",
      path_var: "/education/fluorides",
      content_html: EduCont.FLUORIDES,
      head_title: "Oral Health Wellness",
      eduheader: 1,
      display: true,
    },
    {
      title: "Mouthrinses",
      pic_url: "/dental_icons/mouthrinses.png",
      path_var: "/education/mouthrinses",
      content_html: EduCont.MOUTHRINSES,
      head_title: "Oral Health Wellness",
      eduheader: 1,
      display: true,
    },
    {
      title: "Wisdom Tooth",
      pic_url: "/dental_icons/wisdom.png",
      path_var: "/education/wisdom",
      content_html: EduCont.WISDOM,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Tooth Decay",
      pic_url: "/dental_icons/decay.png",
      path_var: "/education/decay",
      content_html: EduCont.DECAY,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Tooth Sentivity",
      pic_url: "/dental_icons/sensitivity.png",
      path_var: "/education/sensitivity",
      content_html: EduCont.SENSITIVITY,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Gum Disease",
      pic_url: "/dental_icons/gumdisease.png",
      path_var: "/education/gumdisease",
      content_html: EduCont.GUMDISEASE,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Cracked Tooth",
      pic_url: "/dental_icons/crackedtooth.png",
      path_var: "/education/cracked",
      content_html: EduCont.CRACKED,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Ulcers",
      pic_url: "/dental_icons/ulcer.png",
      path_var: "/education/ulcer",
      content_html: EduCont.ULCER,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Barodontalgia",
      pic_url: "/dental_icons/barodontalgia.png",
      path_var: "/education/barodontalgia",
      content_html: EduCont.BARODONTALGIA,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Temporomandibular Joint Disorder",
      pic_url: "/dental_icons/TMD.png",
      path_var: "/education/tmd",
      content_html: EduCont.TMD,
      head_title: "Dental Conditions",
      eduheader: 2,
      display: true,
    },
    {
      title: "Dental FFI",
      pic_url: "/dental_icons/dentalFFI.png",
      path_var: "/education/ffi",
      content_html: EduCont.FFI,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "X-rays",
      pic_url: "/dental_icons/xrays.png",
      path_var: "/education/xray",
      content_html: EduCont.XRAY,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Extractions",
      pic_url: "/dental_icons/extraction.png",
      path_var: "/education/extraction",
      content_html: EduCont.EXTRACTION,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Root Canal Treatment",
      pic_url: "/dental_icons/RCT.png",
      path_var: "/education/rct",
      content_html: EduCont.RCT,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Crowns",
      pic_url: "/dental_icons/crown.png",
      path_var: "/education/crown",
      content_html: EduCont.CROWN,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Bridges",
      pic_url: "/dental_icons/bridge.png",
      path_var: "/education/bridge",
      content_html: EduCont.BRIDGE,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Dentures",
      pic_url: "/dental_icons/denture.png",
      path_var: "/education/denture",
      content_html: EduCont.DENTURE,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Implants",
      pic_url: "/dental_icons/implant.png",
      path_var: "/education/implant",
      content_html: EduCont.IMPLANT,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Nightguards",
      pic_url: "/dental_icons/nightguard.png",
      path_var: "/education/nightguard",
      content_html: EduCont.NIGHTGUARD,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
    {
      title: "Tooth Whitening",
      pic_url: "/dental_icons/dentalFFI.png",
      path_var: "/education/whitening",
      content_html: EduCont.WHITENING,
      head_title: "Dental Treatment",
      eduheader: 3,
      display: true,
    },
  ];
}
