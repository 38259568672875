export class EduCont {
  static readonly BARODONTALGIA=`
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#Barodontalgia"><div class="caq"><span class="plus">+</span>What is Barodontalgia / Aerodontalgia?</div></a>
  <a style="text-decoration:none" href="#causes"><div class="caq"><span class="plus">+</span>Causes of Barodontalgia</div></a>
  <a style="text-decoration:none" href="#happen"><div class="caq"><span class="plus">+</span>How does this happen?</div></a>
  <a style="text-decoration:none" href="#prevent"><div class="caq"><span class="plus">+</span>How to prevent barodontalgia?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >What is Barodontalgia / Aerodontalgia?</span>
  <div class="contentP" id="Barodontalgia">
    <p class="p1image">
      <img src="/images/barodontalgia.png" height=250/>
    </p>
    <p>
      <ul>
        <li>Tooth <b>pain</b> caused by a <b>change in atmospheric pressure</b></li>
        <li>Usually happens to people who fly or dive</li>
        <li>Symptom of a pre-existing oral disease</li>
        <li>Healthy teeth will not experience barodontalgia</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >Causes of Barodontalgia</span>
  <div class="contentP" id="causes">
    <p>
      Barodontalgia is caused by:
      <ul>
        <li>Tooth decay</li>
        <li>Defective fillings</li>
        <li>Cracked tooth</li>
        <li>Nasal congestion</li>
        <li>Inflammation of the ear</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How does this happen?</span>
  <div class="contentP" id="happen">
    <p>
      <u>On descent during diving</u>:
      <ul>
        <li>Any existing air space (caused by decay or defective fillings) fails to adjust its internal pressure to the external pressure, causing pain</li>
      </ul>
      <u>On ascent during diving</u>:
      <ul>
        <li>During descent, compressed air slowly enters the teeth that have defective restorations but cannot escape quickly enough during ascent</li>
        <li>As the diver’s depth decreases, pressure builds up within the tooth due to expansion of the trapped air leading to severe pain and sometimes even fractures</li>
      </ul>
    </p>
    <p>
      <u>On ascent when flying</u>:
      <ul>
        <li>The external pressure decreases while the volume of gases increase in a compromised tooth</li>
        <li>Existing air spaces in defective restorations, recurrent decay or cracked tooth can cause pain</li>
        <li>Crowns may even be dislodged if the cement layer under the crowns has micro air bubbles</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How to prevent barodontalgia?</span>
  <div class="contentP" id="prevent">
    <p>
      <ol>
        <li>Maintain good oral health</li>
        <li>Go for regular dental check ups (to check if you have tooth decay or damaged fillings)</li>
        <li>Avoid flying/diving for the next 7 days after a surgical procedure / tooth extractions</li>
        <li>Avoid flying/diving for the next 24 hours after a dental procedure requiring anaesthetic</li>
      </ol>
    </p>
  </div>
  `;

  static readonly BRIDGE=`
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#bridge"><div class="caq"><span class="plus">+</span>What is a bridge?</div></a>
  <a style="text-decoration:none" href="#how"><div class="caq"><span class="plus">+</span>How is a bridge made?</div></a>
  <a style="text-decoration:none" href="#alternatives"><div class="caq"><span class="plus">+</span>What are some alternatives to replacing teeth with bridges?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >What is a bridge?</span>
  <div class="contentP" id="bridge">
    <p>
      A dental bridge is a treatment option to replace a missing tooth space with a fixed prosthesis.
    </p>
    <p>
      The <b>conventional bridge</b> usually consists of two teeth on either side of the missing space, which will be turned into crowns, with a fake tooth connecting them.
    </p>
    <p class="p1image">
      <img src="/images/conventionalbridge.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/51261898039/">Conventional Bridge</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
    </p>
    <p>
      The <b>Maryland bridge</b> or <b>Acid-Etch bridge</b> has “wings” on either side, and this can be cemented onto the neighbouring teeth with minimal trimming done on them.
    </p>
    <p class="p1image">
      <img src="/images/aeb.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/51261898039/">Maryland Bridge</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
    </p>
    <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@drtejaspatel/video/6878324001832242437" data-video-id="6878324001832242437" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@drtejaspatel" href="https://www.tiktok.com/@drtejaspatel">@drtejaspatel</a> What is a dental bridge? <a title="dentalfacts" target="_blank" href="https://www.tiktok.com/tag/dentalfacts">#dentalfacts</a> <a title="goodtoknow" target="_blank" href="https://www.tiktok.com/tag/goodtoknow">#goodtoknow</a> <a title="dentaleducation" target="_blank" href="https://www.tiktok.com/tag/dentaleducation">#dentaleducation</a> <a title="options" target="_blank" href="https://www.tiktok.com/tag/options">#options</a> <a title="dentist" target="_blank" href="https://www.tiktok.com/tag/dentist">#dentist</a> <a target="_blank" title="♬ NOTHING - Westover" href="https://www.tiktok.com/music/NOTHING-6773888441802311681">♬ NOTHING - Westover</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>
    <p>
      The bridge must be well taken care of, and regular checks to ensure that everything is okay.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How is a bridge made?</span>
  <div class="contentP" id="how">
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/V5sOfs_R0AA" title="Dental bridge - Fixed dental replacement" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <span class="eduTemplateHTMLContainer" >What are some alternatives to replacing teeth with bridges?</span>
  <div class="contentP" id="alternatives">
    <p>
      Other treatment options for missing teeth are <b>implants</b>, or <b>removable dentures</b>. Find out more about them in the respective pages.
    </p>
  </div>
  `;

  static readonly BRUSHING = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#whybrush"><div class="caq"><span class="plus">+</span>Why brush?</div></a>
  <a style="text-decoration:none" href="#howfreq"><div class="caq"><span class="plus">+</span>How frequently?</div></a>
  <a style="text-decoration:none" href="#howbrush"><div class="caq"><span class="plus">+</span>How to brush?</div></a>
  <a style="text-decoration:none" href="#toothbrushpaste"><div class="caq"><span class="plus">+</span>What toothbrush and toothpaste should I choose?</div></a>
  <a style="text-decoration:none" href="#gumbleed"><div class="caq"><span class="plus">+</span>What if my gums bleed when I brush?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >Why brush?</span>
  <div class="contentP" id="whybrush">
    <p>
      Daily oral hygiene care and maintenance is important in preserving the health of our teeth and gums, which will help us to eat, speak, and socialize without pain, discomfort, or embarrassment.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How frequently?</span>
  <div class="contentP" id="howfreq">
    <p>
      It is recommended to brush at least twice daily and floss once a day with the correct techniques.
      Toothbrushes should be replaced every 3-4 months, or when the bristles start to fray.
    </p>
    <p class="p1image">
      <img src="/images/frayedtoothbrush.jpeg" height=200/><br />
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How to brush?</span>
  <div class="contentP" id="howbrush">
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/-j2Y_xSlDOY" title="Modifed Bass Technique" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>
      For most adults, the <b>Modified Bass Technique</b> is advised. Do not rush through the brushing, which should take around <b>2 minutes</b>. For adults, use a pea-sized amount of fluoridated toothpaste.
      <br /><br />
      For the outside and inside surfaces of the teeth:
      <ul>
        <li>Toothbrush to be angled 45 degrees towards the gums, and placed at the gumline.</li>
        <li>Cover 2-3 teeth at a time, and vibrate toothbrush in a circular fashion, 8-10x.</li>
        <li>Sweep the toothbrush away from the gums 1-2x.</li>
        <li>Move on to the next two teeth and repeat.</li>
      </ul>
      For the inside surfaces of the front teeth:
      <ul><li>Tilt toothbrush vertically and brush one tooth at a time with an up-and-down motion, 8-10x.</li></ul>
      Lastly, brush the chewing surface of each tooth with a back-and-forth motion.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What toothbrush and toothpaste should I choose?</span>
  <div class="contentP" id="toothbrushpaste">
    <p>
      <ul>
        <li>Use a <b>soft-bristled toothbrush</b> and gentle strokes to avoid damaging teeth and gums.</li>
        <li>If unable to reach certain areas of the mouth well, consider switching to a toothbrush with a smaller head.</li>
        <li>Toothpaste should contain <b>at least 1,000 ppm</b> fluoride which will help to strengthen the enamel of teeth. Popular brands include Colgate, Sensodyne, Pearlie White, Oral-B.</li>
        <li>Users can consider electric toothbrushes. With an electric toothbrush, the head can be held in place, 45 degrees into the gum and the vibratory motions will automatically clean the tooth for you. There is no longer a need to manually induce vibratory motions. Popular brands include Oral-B and Phillips.</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What if my gums bleed when I brush?</span>
  <div class="contentP" id="gumbleed">
    <p>
      Gum bleeding is due to inflamed / ulcerated gums from bacteria accumulation.
      If there is bleeding at a certain area, <b>continue to brush</b> the gumline well, and the bleeding should subsided after 1-2 weeks.
      Severe bleeding or receeding gums is a sign of advanced gum disease, also known as Periodontitis.
    </p>
    <p>
      You can read more about Periodontitis in the Gum Disease Education Module.
    </p>
  </div>
  `;

  static readonly CRACKED = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#cracked"><div class="caq"><span class="plus">+</span>What is a cracked tooth?</div></a>
  <a style="text-decoration:none" href="#causescracked"><div class="caq"><span class="plus">+</span>What can cause a cracked tooth?</div></a>
  <a style="text-decoration:none" href="#symptoms"><div class="caq"><span class="plus">+</span>What are some symptoms I may experience?</div></a>
  <a style="text-decoration:none" href="#treatment"><div class="caq"><span class="plus">+</span>How can I treat my cracked tooth?</div></a>
  <a style="text-decoration:none" href="#avoid"><div class="caq"><span class="plus">+</span>How can I avoid cracked teeth?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >What is a cracked tooth?</span>
  <div class="contentP" id="cracked">
    <p>
      As the name suggests, it is a tooth that has developed the presence of a crack. It can be isolated to the crown of the tooth or even spread to the root of the tooth.
    </p>
    <p class="p1image">
      <img src="/images/cracked1.png" height=300/><br />
      <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Cracked_tooth.png">Picture</a> by Coronation Dental Specialty Group (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>)</span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What can cause a cracked tooth?</span>
  <div class="contentP" id="causescracked">
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/Kjl9widobeQ" title="Understanding Cracked Tooth Treatment and Symptoms" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>
      <ol>
        <li>Biting on hard foods like ice cubes, crab shells, peanuts</li>
        <li>Parafunctional habits like teeth grinding, clenching</li>
        <li>Injury due to trauma</li>
        <li>History of large fillings</li> 
        <li>General wear and tear</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What are some symptoms I may experience?</span>
  <div class="contentP" id="symptoms">
    <p>
      Cracked teeth demonstrate a broad range of symptoms. However, the symptoms of cracked teeth can be <b>non-specific</b>.
      <ul>
        <li>Pain when biting</li>
        <li>Pain on release of biting pressure</li> 
        <li>Increased sensitivity to hot/cold temperatures</li>
        <li>Spontaneous pain</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How can I treat my cracked tooth?</span>
  <div class="contentP" id="treatment">
    <p>
      The treatment and outcome for a cracked tooth depends on the type, location and extent of the crack.
      <br /><br />
      Crack assessment must first be done. The dentist will isolate and identify the offending tooth and assess the severity of the crack.
      <br /><br />
      As an interim treatment, a metal band may be placed on the tooth to hold the tooth together and prevent propagation of the crack. It will also serve as a tool to assess the potential effectiveness of a crown to be placed over the tooth. If the metal band is deemed effective after an observation period, a permanent crown can be placed.  
      <br /><br />
      If severe symptoms persist despite interim treatment, the nerve may have been irreversibly affected by the crack. Root canal treatment may be required to relieve pain. However, the crack will still be present. A crown will often be placed to prevent further propagation of the crack.
      <br /><br />
      For deep cracks that extend beyond the root surface, the tooth will be deemed unrestorable and will have to be extracted.
      <br /><br />
      It is important to note that cracks cannot be cured, and will likely continue to propagate over time despite crown coverage. Survival rates of cracked teeth are 70% over 5 years.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How can I avoid cracked teeth?</span>
  <div class="contentP" id="avoid">
    <p>
      <ul>
        <li>Abstain from biting on hard foods (e.g. nuts / crab shells / ice cubes)</li>
        <li>Fabricate a custom night guard with your dentist if you have tooth grinding habit (bruxism)</li>
        <li>Do not clench / grind your teeth</li>
        <li>Early identification by going for regular dental checkups</li>
      </ul>
    </p>
  </div>
  `;

  static readonly CROWN =`
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#crown"><div class="caq"><span class="plus">+</span>What is a crown?</div></a>
  <a style="text-decoration:none" href="#whyneedcrown"><div class="caq"><span class="plus">+</span>Why do I need a crown?</div></a>
  <a style="text-decoration:none" href="#crowndone"><div class="caq"><span class="plus">+</span>How is a crown done?</div></a>
  <a style="text-decoration:none" href="#risk"><div class="caq"><span class="plus">+</span>Are there risks involved with crowning?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >What is a crown?</span>
  <div class="contentP" id="crown">
    <p>
      A crown is a tooth-shaped ‘cap’ or ‘helmet’ that is used to restore the strength, shape and/or aesthetics of a tooth.
    </p>
    <p class="p1image">
      <img src="/images/crown1.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729002173/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >Why do I need a crown?</span>
  <div class="contentP" id="whyneedcrown">
    <p>
      A crown may be indicated in the following situations:
      <ul>
        <li>To protect weakened teeth e.g. root canal treated / cracked / badly decayed teeth</li>
        <li>To change the shape and form of the tooth</li>
        <li>To complete the implant</li>
      </ul>
      There are multiple materials that a crown can be fabricated from. Please discuss with your attending dentist regarding the material choice based on the situation presented.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How is a crown done?</span>
  <div class="contentP" id="crowndone">
    <p>
      <ol>
        <li>During the first visit, the dentist will discuss with you the indications and recommendations for the dental crown</li>
        <li>
          During the second visit, the dentist will start to prepare (trim) the tooth in question to create space for the crown to be seated
          <ol style="list-style-type:lower-alpha;">
            <li>A local anaesthetic injection will be given to numb up the area to relieve any pain during the procedure</li>
            <li>An impression will be taken and sent to the lab for fabrication for the crown</li>
            <li>A temporary crown will be placed – care must be taken to avoid hard and sticky food. If the temporary crown breaks or dislodges, please bring it along and see your dentist to re-cement it</li>
          </ol>
        </li>
        <li>During the third visit, the temporary crown is removed. The final crown is fitted and cemented with a strong cement.</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >Are there risks involved with crowning?</span>
  <div class="contentP" id="risk">
    <p>
      <ol>
        <li>The crown preparation procedure involves drilling. Heat is produced as a by-product during drilling, and this may cause irreversible injury to the tooth. There is up to 20% risk of irreversible nerve damage requiring the tooth to undergo root canal treatment (if the tooth is still vital). This does not concern teeth which have already undergone root canal treatment.</li>
        <li>The crown requires sufficient remaining tooth structure to support it. If there is insufficient tooth structure remaining, placing a crown will predispose the tooth to a higher risk of catastrophic fracture. Please discuss with the dentist the pros and cons of placing a crown.</li>
        <li>The crown requires maintenance of excellent oral hygiene, as the tooth beneath the crown will still be prone to tooth decay and gum disease. However, no additional ‘special’ care is needed.</li>
      </ol>
    </p>
  </div>
  `;

  static readonly DECAY = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#decay"><div class="caq"><span class="plus">+</span>What is Decay?</div></a>
  <a style="text-decoration:none" href="#cause"><div class="caq"><span class="plus">+</span>What are some causes of tooth decay?</div></a>
  <a style="text-decoration:none" href="#treatment"><div class="caq"><span class="plus">+</span>What treatment can I seek for tooth decay?</div></a>
  <a style="text-decoration:none" href="#filling"><div class="caq"><span class="plus">+</span>What is a dental filling?</div></a>
  <a style="text-decoration:none" href="#afterfilling"><div class="caq"><span class="plus">+</span>What can I expect after treatment (tooth filling)?</div></a>
  <a style="text-decoration:none" href="#prevent"><div class="caq"><span class="plus">+</span>How can I prevent decay?</div></a>
  <a style="text-decoration:none" href="#untreated"><div class="caq"><span class="plus">+</span>What happens if decay is left untreated?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >What is Decay?</span>
  <div class="contentP" id="decay">
    <p>
      Our mouths contain many types of bacteria, which can gradually form plaque. The bacteria in plaque can break down the sugars and carbohydrates in the food we take, and form acid.
      <br /><br />
      Tooth decay, otherwise known as dental caries, is caused by an attack of acid on the outermost surface of the tooth, enamel.
    </p>
    <p class="p1image">
      <img src="/images/decay1.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Dental_Caries_Cavity_2.JPG">Picture</a> by Suyash.dwivedi (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>)</span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What are some causes of tooth decay?</span>
  <div class="contentP" id="cause">
    <p>
      <ol>
        <li>Diet content - diet with high sugar/carbohydrate increases risk of decay</li>
        <li>Frequent snacking</li>
        <li>Not brushing well, not using fluoridated toothpaste</li>
        <li>Not flossing frequently</li>
        <li>Persistent food trap - may have higher risk if you are wearing dental appliances e.g. braces/aligners/retainers/dentures</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What treatment can I seek for tooth decay?</span>
  <div class="contentP" id="treatment">
    <p>
      For initial decay confined to the outermost layer (enamel) that has no cavitation, topical fluoride can be applied first and the decay be monitored for progression. The tooth may be able to be left alone if initial decay does not progress or if decay becomes arrested. However, if there are signs of progression, a filling may be required. 
      <br /><br />
      For decay with small to moderate cavities, fillings can be done. Some drilling is usually required to remove the decayed or “unhealthy” portions of the tooth before the filling can be placed.
      <br /><br />
      For large cavities involving the pulp, root canal treatment may be needed, possibly followed by a crown.
      <br /><br />
      When decay has led to loss of extensive tooth structure, extraction may be the only option.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What is a dental filling?</span>
  <div class="contentP" id="filling">
    <p>
      A filling is a material used to close up the cavity after decay is removed, or to repair fractured areas of the tooth.
      <br /><br />
      Fillings generally come in two broad categories, tooth-coloured, and non- tooth-coloured. Temporary fillings are also sometimes used, such as between Root Canal Treatment (RCT) appointments, or to review the tooth at a later date.
      <p class="p1image">
        <img src="/images/AR.jpg" height=200/><br />
        <span class="imgcredits">
          <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729829177/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
        </span>
      </p>
      <u>Amalgam fillings</u> are silver in colour, and can be used for back teeth, where they are not as visible.
      <p class="p1image">
        <img src="/images/TCR.jpg" height=200/><br />
        <span class="imgcredits">
          <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729829142/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
        </span>
      </p>
      <u>Tooth-coloured filling</u> materials are usually a resin or glass-ionomer material, and are more aesthetic.
      <br /><br />
      If well-taken care of, a dental filling can usually last up to 5-10 years, and even beyond. It is important to note that without proper oral hygiene, decay can occur again.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What can I expect after treatment (tooth filling)?</span>
  <div class="contentP" id="afterfilling">
    <p>
      The tooth may feel a little sensitive after the filling is placed, which usually subsides in days or weeks after the filling is placed. Take painkillers like Panadol as required.
      <br /><br />
      Avoid biting on hard objects on the tooth with the newly placed filling. In general, for amalgam and some tooth-coloured fillings, hard food should be avoided in that area for 24 hours. For more specifics, check with your dentist after placement of the filling.
      <br /><br />
      If there is persistent pain or increased sensitivity for weeks, the nerve of the tooth may be affected, and it may require Root Canal Treatment (RCT).
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >How can I prevent decay?</span>
  <div class="contentP" id="prevent">
    <p>
      Brush and floss with the correct techniques, and visit your dentist to review if your oral hygiene is acceptable. Check with your dentist the recommended frequency to visit them, which is between usually 6 months to a year.
      <br /><br />
      Always use fluoridated oral health products (toothpastes, mouthrinses)
      <br /><br />
      Avoid frequent snacking between meals, if possible to limit sugar intake. Otherwise, try to consume sugar together with your meals instead of in between meals.
      <br /><br />
      Read more about personal oral care in the "Oral Health Wellness" section on the homepage of this app.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >What happens if decay is left untreated?</span>
  <div class="contentP" id="untreated">
    <p>
      If intial decay is left untreated, the decay will progress to the next layer of the tooth, dentine. At this point, there might be a hole caused by the decay, otherwise known as a cavity. This is the second stage of decay, and the disease is irreversible at this stage. The affected tooth will require a filling.
      <br /><br />
      As the disease progresses, the nerve in the tooth, otherwise known as the pulp, may become affected and inflamed, which is also known as pulpitis. At this stage, there may or may not be involvement of pain and/or discomfort. A filling may not suffice at this point, and the tooth may require a Root Canal Treatment (RCT). 
      <br /><br />
      Finally, when the decay has reached the end of the nerve, an abscess may form, which is usually a bacterial infection and collection of pus. This usually results in a swelling of the gums, and there may be a discharge of pus through a sinus tract, a pimple-like structure on the gums. The tooth will require Root Canal Treatment (RCT) for pain and swelling relief, or an extraction.
    </p>
  </div>
  `;

  static readonly DENTURE=`
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#dentures"><div class="caq"><span class="plus">+</span>What are Dentures and are there different types?</div></a>
  <a style="text-decoration:none" href="#advantages"><div class="caq"><span class="plus">+</span>What are the advantages and disadvantages of wearing a denture?</div></a>
  <a style="text-decoration:none" href="#howlong"><div class="caq"><span class="plus">+</span>How long does it take to make a denture?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What are Dentures and are there different types?</span>
  <div class="contentP" id="dentures">
    <p>
      Dentures are commonly known as removable “fake” teeth made of acrylic or metal that replaces missing teeth. Dentures can be used for eating, speaking, looking good, and may prevent your existing teeth from shifting.
      <br /><br />
      Removable dentures can come in a few forms.
      <br /><br />
      Complete dentures are suitable for patients with no teeth in their upper or lower jaw. These dentures are usually broad and are required to cover large areas for enhanced support.
      <br /><br />
      Partial dentures are suitable for patients with some teeth left. These dentures are held in the mouth with hooks better known as clasps, and/or can be slotted in the mouth with a precisely-designed path of insertion. Partial dentures can be made entirely in acrylic resin, or partially reinforced with metal (using cobalt-chromium alloy).
      <br /><br />
      A temporary partial denture, also known as a flipper, may be indicated for tooth replacement immediately after extraction so that you will never go without a tooth. These are primarily meant for esthetics and should not be used for biting.
      <br /><br />
      Overdentures are dentures that sit on top of the patient’s gums which are held in place by dental implants or even ground-down natural teeth.These can be placed on the upper and/or lower jaw, depending on the patient’s needs. They are also removable.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What are the advantages and disadvantages of wearing a denture?</span>
  <div class="contentP" id="advantages">
    <p>
      The denture option is better suited for patients who:
      <ol>
        <li>Cannot afford dental implants or other dental treatment due to the lack of sufficient bone to support implant rehabilitation, sufficient teeth to support a bridge and/or costs concerns.</li>
        <li>A ready solution to replace multiple missing teeth scattered across the mouth, or a temporary denture to replace a tooth right after an extraction is done.</li>
        <li>Have severe dental or periodontal conditions that will be expected to lose more teeth in the near future.</li>
      </ol>
      The considerations you should have before making a denture are as follows:
      <ol>
        <li><u>Period of adaptation</u>. There will be a “break-in” time required for you to adjust to a foreign object in your mouth. During this phase, you may experience some discomfort, increase salivation, possible ulceration on your gums and soft tissues, and tongue obstruction. Fret not! This will normally go away with time as you get more used to wearing the denture.</li>
        <li><u>Reduced eating function</u>. Dentures are removable objects and will experience movement in the mouth during function. You might find your eating efficiency reduced as compared to having your natural teeth or fixed dental prostheses such as bridges or implants.</li>
        <li><u>Looseness and Increased food trap</u>. Dentures are not meant to firmly adhere to your tissues. Sometimes, food and liquids might get caught under the space between the denture and your gums/ palate. If the dentures are very loose, it is advisable to consult a dental professional to investigate the cause and possibly remake a new denture. Otherwise, denture adhesives will be the next alternative.</li>
        </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How long does it take to make a denture?</span>
  <div class="contentP" id="howlong">
    <p>
      The process of making a denture typically takes three to six visits to complete, depending on the complexity.
      <br /><br />
      Each visit is normally spaced 2 - 3 weeks apart, which is sufficient for the laboratory to receive, complete and deliver the finished products. 
      <br /><br />
      The first visit usually involves a thorough dental examination to understand your dental history and oral condition, personal preference and financial concerns. A preliminary mould might be taken to study your dental condition.
      <br /><br />
      The subsequent visits will involve making the final mould (when necessary), recording your biting relationship, verification of esthetics and finally the issue of the denture. A repeated visit might be necessary to address any problems encountered. Lastly, you will be encouraged to return for dental reviews to make fine adjustments to improve the fit of your denture.
    </p>
  </div>
  `;

  static readonly EXTRACTION = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#extraction"><div class="caq"><span class="plus">+</span>What are tooth extractions?</div></a>
  <a style="text-decoration:none" href="#needextraction"><div class="caq"><span class="plus">+</span>Do I need an extraction?</div></a>
  <a style="text-decoration:none" href="#prepare"><div class="caq"><span class="plus">+</span>What can I expect? How can I prepare for my extraction visit?</div></a>
  <a style="text-decoration:none" href="#postextraction"><div class="caq"><span class="plus">+</span>Post-Extraction Instructions</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What are tooth extractions?</span>
  <div class="contentP" id="extraction">
    <p>
      Tooth extraction is the removal of a tooth from the socket of the jawbone, which is usually easily seen in the mouth. 
      <br /><br />
      Your dentist will consider alternative methods to save the tooth before recommending extraction as a treatment option, which may help to prevent pain, swelling and infection in future.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Do I need an extraction?</span>
  <div class="contentP" id="needextraction">
    <p>
      If you have one of the following conditions, you may require a tooth extraction. Do consult your dentist to find out more.
      <ol>
        <li>Tooth that is badly broken down or has a huge cavity, usually accompanied with severe, spontaneous pain</li>
        <li>Very shaky tooth</li>
        <li>Cracked/split teeth</li>
        <li>Pre-braces treatment to create space</li>
        <li>Non-functional tooth (e.g. upper wisdom teeth where there is no opposing erupted wisdom tooth)</li>
      </ol>
    </p>
    <p class="p1image">
      <img src="/images/extraction.jpeg" height=250/><br />
      <span class="imgcredits">
        <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729828892/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
      </span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What can I expect? How can I prepare for my extraction visit?</span>
  <div class="contentP" id="prepare">
    <p>
      A dental x-ray will usually have to be taken first to determine the condition of the tooth.
    </p>
    <p>
      Your dentist will administer a local anesthesia to numb the area. Do inform your dentist if you are not numb during the procedure. The area will usually be numb for 2-3 hours, so avoid biting on your lips and cheeks as this may cause bruising or ulcerations.
    </p>
    <p>
      You should sleep well the day before, and do not skip your meal before the dental visit. If you are on any medications such as blood thinners or anti-hypertensives, there is no need to stop the medications unless directed to. Do inform your dentist of any such medical conditions or long-term medications, preferably in the visit prior, so they can make the necessary arrangements if any.
    </p>
    <p>
      1-2 days of MC is usually given after an extraction. Gauze will be provided to you to help with the expected bleeding, while medications will be dispensed for the expected pain and swelling.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Post-Extraction Instructions</span>
  <div class="contentP" id="postextraction">
    <p>
      <ul>
        <li><u>Avoid frequent rinsing or spitting</u> as this may dislodge the blood clot and cause bleeding to continue.</li>
        <li><u>Avoid sucking through a straw vigorously or stifling sneezes</u>, especially after the extraction of an upper back tooth.</li>
        <li><u>Avoid exercise or vigorous activities</u> for 1-2 days.</li>
        <li><u>Avoid disturbing the wound</u> with your tongue, finger, or a utensil. This may cause irritation, infection, and/or bleeding.</li>
        <li><u>Avoid smoking and alcohol</u> for 1-2 days, preferably up to 5 days as this increases the risk of infection or poor wound healing.</li>
        <li><u>Go on a soft diet</u> and chew food using the unaffected side for 24 hours.</li>
        <li><u>Take medications as directed</u> - if antibiotics are prescribed, the full course of antibiotics must be completed, while painkillers can be taken as needed until pain resolves.</li>
        <li><u>Expect mild oozing</u> - If bleeding occurs, bite hard on gauze for 30 minutes. Remove the gauze after that and repeat if necessary. If bleeding persists and is profuse in nature, return to dentist in the day or a hospital A&E after office hours</li>
      </ul>
    </p>
  </div>
  `;

  static readonly FFI = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#ffi"><div class="caq"><span class="plus">+</span>What are Dental FFIs?</div></a>
  <a style="text-decoration:none" href="#screening"><div class="caq"><span class="plus">+</span>Why do I need to go for dental screening?</div></a>
  <a style="text-decoration:none" href="#classification"><div class="caq"><span class="plus">+</span>Classification of Dental FFIs</div></a>
  <a style="text-decoration:none" href="#types"><div class="caq"><span class="plus">+</span>What are the types of Dental FFIs?</div></a>
  <a style="text-decoration:none" href="#howoften"><div class="caq"><span class="plus">+</span>How often do I need to do my FFI?</div></a>
  <a style="text-decoration:none" href="#whattodo"><div class="caq"><span class="plus">+</span>I can’t meet my FFI requirement. What should I do?</div></a>
  <a style="text-decoration:none" href="#funfact"><div class="caq"><span class="plus">+</span>Additional fun fact</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What are Dental FFIs?</span>
  <div class="contentP" id="ffi">
    <p>
      Dental FFIs (known as Fit-For-Instructions) are comprehensive dental screenings conducted by trained Dental Officers to identify any dental issues faced by a serviceman/servicewoman and to ensure that he/she is dentally fit to perform his/her operational role.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Why do I need to go for dental screening?</span>
  <div class="contentP" id="screening">
    <p>
      Dental FFIs function as pre-deployment dental screening to identify and advise on any oral conditions that may potentially affect the performance of a serviceman abroad. Just as how a small toothache can turn a large navy vessel around, dental pain requiring urgent care can hamper the effectiveness of the detachment team. Such undesired events can be prevented with proper dental check-ups and necessary interventions.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Classification of Dental FFIs</span>
  <div class="contentP" id="classification">
    <p>
      The Dental Fitness Classification takes reference from NATO’s standards.
    </p>
    <p>
      <b>D1</b> - Dentally Fit for 12 months of deployment (Highest Dental Fitness Classification, pegged to NATO’s standards of D2)<br />
      <b>D2</b> - Conditionally Dental Fit for 6 months of deployment<br />
      <b>D3</b> - Marginally Dental Fit for 3 months of deployment<br />
      <b>D9</b> - Dentally Unfit, non-deployable.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What are the types of Dental FFIs?</span>
  <div class="contentP" id="types">
    <p>
      <ol>
        <li><b>Dental Screening for Overseas Deployment</b> - These FFIs are meant for overseas deployment for more than 30 days. This is to ensure that you do not suffer any dental emergencies overseas that may hamper your operational deployability requiring evacuation.</li>
        <li><b>Dental Entrance FFI (Absorption FFI)</b> - These FFIs are conducted for personnel to identify any present dental disease which may preclude them from certain vocations. These FFIs are typically done months before enlistment or induction into service to provide lead time for the personnel to seek dental treatment to improve their dental fitness. Entry FFIs are conducted during Pre-enlistment as well as for servicemen intending to join the service.</li>
        <li><b>Dental ORD FFI</b> - Like the Medical ORD FFI, Dental ORD FFIs are conducted for all servicemen three months prior to leaving the service. These FFIs are meant as the final touchpoint to update the servicemen’s dental records before leaving service, which serves two functions - Antemortem records and as documented records to prevent specious claims of dental injuries sustained during National Service. Lastly, getting a D9 doesn’t mean you cannot be discharged from service. It just means that you do have some outstanding dental conditions that you should follow-up with!</li>
      </ol>
      <i>Note: In general, all deployed navy personnel are required to achieve at least D2 due to the frequency and intensity of their ops frame.</i>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How often do I need to do my FFI?</span>
  <div class="contentP" id="howoften">
    <p>
      Depending on your Dental Fitness status, you are required to attend Dental FFI to reupdate your dental fitness. For those deployed for more than a year overseas, an update of the Dental FFI is required whenever a serviceman returns for home-leave.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">I can’t meet my FFI requirement. What should I do?</span>
  <div class="contentP" id="whattodo">
    <p>
      Not achieving the ideal dental status can be disappointing. But don’t worry! Unlike chronic medical conditions (such as diabetes or hypertension) which can only be managed with long term medications, dental conditions can be treated. You can achieve ideal state of dental fitness by:
      <ol>
        <li>Seeing a dentist regularly</li>
        <li>Practising good oral hygiene (i.e. brushing and flossing)</li>
        <li>Having a proper diet (reduce consumption of sweet / sugary food and drinks)</li>
        <li>Avoid smoking as it may cause periodontal disease and other issues</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Additional funfact</span>
  <div class="contentP" id="funfact">
    <p>
      Statistics from the Iraqi War (Operation Enduring Freedom) showed that the incidence of Dental related Disease Non-battle Injuries (DNBI) in the U.S. Army is 85.8 per 1,000 Soldiers per year. That is nearly 9% of the fighting force requiring in-theatre dental management every year!
    </p>
  </div>
  `;

  static readonly FLOSSING = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#floss"><div class="caq"><span class="plus">+</span>Why should we floss?</div></a>
  <a style="text-decoration:none" href="#howtofloss"><div class="caq"><span class="plus">+</span>How to floss?</div></a>
  <a style="text-decoration:none" href="#misconcep"><div class="caq"><span class="plus">+</span>Common misconceptions about flossing</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">Why should we floss?</span>
  <div class="contentP" id="floss">
    <p>
      Flossing helps to remove plaque in between tight spaces of teeth i.e. in the areas that toothbrush cannot reach. Flossing is recommended once daily at night before or after brushing your teeth.
    </p>
    <p class="p1image">
      <img src="/images/flossing1.jpeg" height=200/><br />
      <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/30478819@N08/49245669591">Picture</a> by Marco Verch (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How to floss?</span>
  <div class="contentP" id="howtofloss">
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/m3pBA4cgdxw?start=14" title="Flossing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>
      <ol>
        <li>Take a piece of approx 30-45cm floss</li>
        <li>Wind the floss around your middle fingers only leaving about 3-6cm to slide between your teeth.</li>
        <li>Place the floss between your teeth, sliding against the side of one tooth. Create a C-shape to ‘hug/wrap’ the tooth it is gliding against, slide it gently beneath the gums (do not force, it should be able to glide nicely approx 1-2mm below the gums) and slide it back up again.</li>
        <li>Repeat on the side of the next tooth.</li>
        <li>Move on from tooth to tooth.</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Common misconceptions about flossing</span>
  <div class="contentP" id="misconcep">
    <p>
      <ul>
        <li>It is expected for gums to bleed when flossing if you do not floss regularly. This is due to inflammation caused by plaque (refer to <a href="/#/education/gumdisease">gum disease</a>). Bleeding will be reduced significantly after 2 weeks of flossing every day!</li>
        <li>Flossing does NOT cause holes between your teeth. These ‘black triangles’ form as a result of gum recession from gum disease. Inflamed and swollen gums fill up the space between our teeth and when we start cleaning between our teeth, the gums now get healthier/less inflamed, thus resulting in them shrinking. When they shrink to become healthy, it shows as ‘black triangles’.</li>
      </ul>
    </p>
  </div>
  `;
  
  static readonly FLUORIDES = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#fluoride"><div class="caq"><span class="plus">+</span>What is fluoride?</div></a>
  <a style="text-decoration:none" href="#benefit"><div class="caq"><span class="plus">+</span>How does fluoride benefit our teeth?</div></a>
  <a style="text-decoration:none" href="#safe"><div class="caq"><span class="plus">+</span>Is fluoride safe?</div></a>
  <a style="text-decoration:none" href="#fluorosis"><div class="caq"><span class="plus">+</span>Can I do anything about dental fluorosis?</div></a>
  <a style="text-decoration:none" href="#found"><div class="caq"><span class="plus">+</span>What dental products can fluoride be found in?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What is fluoride?</span>
  <div class="contentP" id="fluoride">
    <p>
      Fluoride is a naturally existing mineral. It is commonly measured by the units "parts per million (ppm)". In the dental context, they usually exist in 2 forms - stannous fluoride and sodium fluoride. 
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How does fluoride benefit our teeth?</span>
  <div class="contentP" id="benefit">
    <p>
      Early decay is a reversible process. Fluoride helps to strengthen teeth and reduce tooth decay by 3 main actions:
      <ol>
        <li>Halts the breakdown of tooth structure</li>
        <li>Inhibiting bacterial action</li>
        <li>Remineralises tooth surface</li>
      </ol>
      Studies have shown that when fluoride is added to a community's water supply, tooth decay of the people living in the area decreases. As such, fluoride is also found in our community drinking water and the fluoridation level of Singapore's water is at 0.5ppm.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Is fluoride safe?</span>
  <div class="contentP" id="safe">
    <p>
      Fluoride is safe, effective and beneficial when used in appropriate doses.
      </p>
    <p>
      Dental fluorosis is the main risk of an overuse of fluoride. This is a condition that <b>only affects children</b> if they ingest too much fluoride during the development years of their permanent teeth. The fluoride ingested can get incorporated into the tooth surfaces such that it interferes with the normal formation of a tooth's structure. It is thus important that young children spit/rinse thoroughly if using dental products containing fluoride instead of ingesting them. 
      <br /><br />
      Dental fluorosis can range from a chalky white appearance to brown to a mottled look on the tooth's enamel. 
    </p>
    <p class="p1image">
      <img src="/images/fluorosis1.jpg" height=200/><br />
      <span class="imgcredits">
        <a target="_blank" href="https://commons.wikimedia.org/wiki/File:Dental_Flurosis_(teeth_with_brown_stains).jpg">Nizil Shah</a>, <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons
      </span>
    </p>
    <p class="p1image">
      <img src="/images/fluorosis2.jpeg" height=200/><br />
      <span class="imgcredits">
        <a target="_blank" href="https://commons.wikimedia.org/wiki/File:Enamel_celiac.jpg">Maurizio Procaccini et al</a>, <a target="_blank" href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>, via Wikimedia Commons
      </span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Can I do anything about dental fluorosis?</span>
  <div class="contentP" id="fluorosis">
  <p>
  There are different treatment options available for different degrees of fluorosis. Some of them include: microabrasion, veneers, bleaching. Please consult your dentist for advice. 
  </p>
  </div>
  <span class="eduTemplateHTMLContainer">What dental products can fluoride be found in?</span>
  <div class="contentP" id="found">
    <p>
      Fluoride can be found in some foods we eat but is incorporated in many dental products including <b>toothpaste</b> and <b>mouthrinses</b>.
    </p>
    <p>
      Higher concentrations of fluoride are used chairside by dental professionals. They commonly exist in the form of varnish or gel. These are often used on patients who are at a high risk of tooth decay. 
    </p>
  </div>
  `;

  static readonly GUMDISEASE = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#disease"><div class="caq"><span class="plus">+</span>What is gum disease?</div></a>
  <a style="text-decoration:none" href="#periodontal"><div class="caq"><span class="plus">+</span>What causes Periodontal Disease?</div></a>
  <a style="text-decoration:none" href="#otherproblem"><div class="caq"><span class="plus">+</span>What other problems can result from Gum Disease?</div></a>
  <a style="text-decoration:none" href="#gumdisease"><div class="caq"><span class="plus">+</span>How can I treat gum disease?</div></a>
  <a style="text-decoration:none" href="#gumtreatment"><div class="caq"><span class="plus">+</span>What can I expect after gum treatment?</div></a>
  <a style="text-decoration:none" href="#prevent"><div class="caq"><span class="plus">+</span>What can I do to prevent gum disease?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What is gum disease?</span>
  <div class="contentP" id="disease">
    <p>
      Gum disease is a bacterial infection of the gums surrounding the teeth, often due to poor oral hygiene. Plaque (slime layer) tends to form on the surfaces of teeth and in the gum pocket, especially after the consumption of sweet food. If not cleaned regularly through tooth brushing / flossing, it will cause the gums to become inflamed and to bleed easily. Over time, it will also form hard deposits called calculus that can only be removed with the help of a dentist.
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/JryVlJTX9ok" title="Understanding Gum Disease From Colgate" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>
      Gum disease is a chronic infection of tissues surrounding teeth. They can be divided into <u>gingivitis</u> and <u>periodontitis</u>.
    </p>
    <table>
      <tr>
        <td class="p1image">
          <img src="/images/healthygums.jpg" /><br />
          <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Gingivitis-before-and-after-3.jpg">Healthy gums</a> by Onetimeuseaccount, CC0, via Wikimedia Commons</span>
        </td>
        <td class="p1image">
          <img src="/images/gingi.jpg" /><br />
          <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Gingivitis-before.JPG">Gingivitis</a> by Onetimeuseaccount, CC0, via Wikimedia Commons</span>
        </td>
        <td class="p1image">
          <img src="/images/perio.jpeg" /><br />
          <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Plaque_accumulation.jpg">Periodontitis</a> by Shaimaa Abdellatif (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>)</span>
        </td>
      </tr>
      <thead>
        <td>Healthy Gums</td>
        <td>Gingivitis</td>
        <td>Periodontitis</td>
      </thead>
      <tr>
        <td>No gum disease</td>
        <td>Reversible / initial stage gum disease</td>
        <td>Irreversible / late stage gum disease</td>
      </tr>
      <tr>
        <td>Pink gums</td>
        <td>Redness and swelling</td>
        <td>Redness and swelling with permanent gum recession</td>
      </tr>
      <tr>
        <td>No bleeding</td>
        <td>Bleed easily</td>
        <td>Bleeds spontaneously, shaky teeth</td>
      </tr>
      <tr>
        <td>Shallow gum pockets</td>
        <td>Shallow gum pockets</td>
        <td>Deep gum pockets</td>
      </tr>
    </table>
    <p>
      <b>Gingivitis</b> is when the inflammation is limited to the gums surrounding teeth. Inflammation is usually presented as redness, swelling and gums that bleed easily. This inflammation is <b>reversible</b>. 
      <br /><br />
      <b>Periodontitis</b> is an advanced inflammation of not only the gums but also supporting structures of the tooth which include periodontal ligaments and alveolar bone. <b>Irreversible</b> damage is often observed and can manifest in ways such as deep pockets between gums and teeth, gum recession, decrease in alveolar bone level, tooth mobility and eventual loss of teeth.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What causes Periodontal Disease?</span>
  <div class="contentP" id="periodontal">
    <p>
      Bacteria is the main cause of periodontal disease. Bacteria is found in plaque. Plaque is a sticky film of bacteria that forms around teeth.
      <br /><br />
      If plaque is left uncleaned for a prolonged period, they harden and form calculus which cannot be removed by regular tooth brushing. One would require professional cleaning at a dentist to remove it. 
      <br /><br />
      Other factors that could predispose an individual to gum disease include: genetics, smoking, poorly controlled systemic conditions, existing dental prostheses and dental restorations (e.g. dentures/bridges) etc. 
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What other problems can result from Gum Disease?</span>
  <div class="contentP" id="otherproblem">
    <p>
      Bacterial accumulation in the pocket of the gum can lead to a progression of the infection. Gum disease can also spread to the adjacent teeth.
      <br /><br />
      If left untreated, it can progress to form an abscess - an accumulation of pus, often with associated pain and swelling.
      <br /><br />
      In severe cases, there could be systemic involvement during infection which could lead to facial swelling and/or fever. Seek medical attention immediately!
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How can I treat gum disease?</span>
  <div class="contentP" id="gumdisease">
    <p>
      There will be different treatments rendered dependent on the severity of disease that you have.
      <br /><br />
      Gingivitis requires only the routine / basic level of treatment, called <b>Scaling and Polishing</b>.<br />
      Scaling involves the use of an ultrasonic scaler to mechanically break down and remove calculus that is formed around teeth and under the gums. Polishing involves the use of a spinning cup and abrasives to remove residual plaque and stains on teeth surfaces. 
      <br /><br />
      Periodontitis requires a more advanced level of treatment called <b>Root planing (aka deep cleaning)</b>.<br />
      This involves the use of an ultrasonic scaler and/or hand instruments to clean the deep gum pocket. This treatment is usually done over multiple visits. Administration of local anesthesia is often required for this procedure.
      <br /><br />
      In the most severe cases of periodontal disease, <b>periodontal surgery</b> may sometimes be required. This would involve reflecting the gums to gain better visualisation and access to the area that needs to be cleaned. Administration of local anesthesia is required for this procedure.
    </p>
    <p class="p1image">
      <img src="/images/aftersap.jpeg" height=300/><br />
      <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Gingivitis-before-and-after-3.jpg">Before and After Cleaning</a> by Onetimeuseaccount, CC0, via Wikimedia Commons</span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What can I expect after gum treatment?</span>
  <div class="contentP" id="gumtreatment">
    <p>

      <table>
        <thead>
          <td>Symptom</td>
          <td>Management</td>
        </thead>
        <tr>
          <td>Sore gums</td>
          <td>Consider taking over-the-counter painkillers</td>
        </tr>
        <tr>
          <td>Gum bleeding</td>
          <td>Swallow saliva rather than spit out<br />Avoid excessive / vigorous rinsing</td>
        </tr>
        <tr>
          <td>Increased tooth sensitivity to hot/cold drinks</td>
          <td>Generalised tooth sensitivity will be expected for the next few days. You can consider using sensitive toothpaste (e.g. Sensodyne, Colgate Sensitive Pro Relief) or fluoride mouthrinses to reduce the sensitivity.</td>
        </tr>
      </table>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What can I do to prevent gum disease?</span>
  <div class="contentP" id="prevent">
    <p>
      Improving one’s personal oral hygiene is the surest way to keep gum disease at bay.
      <br /><br />
      <i>Read the “Tooth Brushing” and “Flossing” module to find out how you can improve your daily oral hygiene routine!</i>
      <br /><br />
      6 monthly routine visits to the Dentist will also help catch any early-stage gum disease before it’s too late. For patients that have had a history of gum disease, the recall intervals requested by the dentist may be less than 6 months.
    </p>
  </div>
  `;

  static readonly IMPLANT = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#implant"><div class="caq"><span class="plus">+</span>What are Dental Implants?</div></a>
  <a style="text-decoration:none" href="#madeof"><div class="caq"><span class="plus">+</span>What are dental implants made of?</div></a>
  <a style="text-decoration:none" href="#consider"><div class="caq"><span class="plus">+</span>What do I need to consider when I want dental implants?</div></a>
  <a style="text-decoration:none" href="#done"><div class="caq"><span class="plus">+</span>Can I get a dental implant done in SAF?</div></a>
  <a style="text-decoration:none" href="#risk"><div class="caq"><span class="plus">+</span>Are there any risks to doing a dental implant?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What are Dental Implants?</span>
  <div class="contentP" id="implant">
    <p>
      Dental implants are medically graded titanium alloy screws surgically implanted into a jaw to replace a missing tooth or unit.<br />
      This replacement helps a person to regain his/her ability to chew or restore his/her appearance. Watch the video below for more information!<br /><br />
      Dental implants can be used to provide support for artificial teeth like crowns, bridges and dentures.
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/g-i3P-D6p7M" title="Dental Implant Procedure (Medical 3D Animation)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <span class="eduTemplateHTMLContainer">What are dental implants made of?</span>
  <div class="contentP" id="madeof">
    <p>
      Dental implants are made of a titanium alloy consisting of ~85% Ti-6Al-4V and ~15% zirconium. This titanium alloy is one of the more common grade used in the medical industry and has demonstrated high corrosion resistance, strength and toughness.<br />
      Dental implants are made of materials that are evaluated according to International consensus standards. Biocompatibility testing, to show that bodily contact with the device does not cause complications like irritation or allergic reaction, is part of the evaluation that helps ensure the materials in the dental implant system are safe and do not cause adverse effects when implanted in people. That is why dental implants are so costly!
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What do I need to consider when I want dental implants?</span>
  <div class="contentP" id="consider">
    <p>
      <p><u>The need to replace teeth</u></p>
      Dental Implants do NOT replace ‘teeth’ but dental implants CAN replace ‘missing teeth’. Overall, your natural tooth should be preserved, especially if the tooth is still in a good condition.

      <p><u>Medical Condition</u></p>
      Your overall health is important before considering the suitability to undergo implant surgery. Medical conditions such as Diabetes Mellitus may affect the healing process of the dental implant and predispose it to early implant failure or implant disease.
      Patients with a history of radiation therapy or consumption of IV/Oral bisphosphonates to the jaw are not recommended to have dental implants due to the risk of Osteonecrosis of the Jaw (ONJ)

      <p><u>Smoking</u></p>
      Smoking may affect the healing process of the dental implant and decrease the long-term success of the implant.
      Studies have shown that current smokers are more likely to develop peri-implantitis than non-smokers, and that the severity of the condition is dose-dependent. That means, the more cigarettes you indulge in, the more likely you would experience peri-implant disease. 

      <p><u>Oral Hygiene and History of Gum Disease</u></p>
      Studies have shown that poor oral hygiene will put your implant 3.8 times more likely to be affected by peri-implantitis! (Serino & Strom 2009).
      Furthermore, if you had a history of tooth loss due to gum disease, it is highly likely that you may not have enough biological bone to support any implant replacement.

      <p><u>Ability to undergo surgeries</u></p>
      Implant placement is considered as a dental surgical procedure. Post-operative bleeding and pain will be expected. If you have any medical conditions precluding you from undergoing surgeries, or simply just afraid of undergoing surgical procedures, dental implants are NOT for you.

      <p><u>Number of Missing Teeth</u></p>
      Dental implants are not super structures. The number of missing teeth will correspond to the number of implants required. If you do have multiple missing teeth scattered across the dental arch, there might be more cost savings to make a denture instead!
      However, in some cases, multiple teeth may be restored with an implant-supported bridge (usually consisting of more than two implants) as depicted in the picture below.
      
      <p><u>Costs</u></p>
      Dental implants can be quite costly, as they are considered elective procedures that does not receive any government subsidies. Speak to your dental professional to find out how much these will costs.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Can I get a dental implant done in SAF?</span>
  <div class="contentP" id="done">
    <p>
      Dental implants can be done for servicemen who has a history of service injury resulting in the total tooth loss. These procedures will be only performed by Implant Specialists. (See video on implant surgical procedures)<br /><br />
      Servicemen who do not produce a Service-Injury (SI) card are not eligible for dental implants in the SAF. They may consider going to a private dental clinic / public dental institution for an initial consultation.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Are there any risks to doing a dental implant?</span>
  <div class="contentP" id="risk">
    <p>
      Dental implants can remarkably improve your quality of life and the health of a person who needs them. However, complications may sometimes occur (looseness of implant or presence of infection). Such complications may require additional remedy surgery to fix or replace the failing implant.<br /><br />
      The other surgical risks for dental implant procedures are as follows:
      <ul>
        <li>Damage to surrounding teeth during implant placement</li>
        <li>Damage to surrounding tissues, such as sinus perforation</li>
        <li>Injury to the jaw resulting in jaw fracture</li>
        <li>Injury to the nerve resulting in post-surgical numbness</li>
        <li>Post-operative bleeding, swelling or infection</li>
        <li>Early implant failure resulting in looseness and loss of implant</li>
      </ul>
      Dental implants can be susceptible to gum disease and technical complications. After surgery and installation of the dental implant, regular self care and maintenance is still required. The biological and technical issues that can happen to a dental implant are:
      <ul>
        <li>Inability to clean the dental implant resulting in peri-implant disease and exposure of implant threads</li>
        <li>Screw loosening and fracture requiring replacement</li>
        <li>Implant crown chipping and damage requiring replacement</li>
        <li>Inadequate function, such as poor biting ability</li>
      </ul>
    </p>
  </div>
  `;

  static readonly MOUTHRINSES = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#mouthrinse"><div class="caq"><span class="plus">+</span>What are mouthrinses?</div></a>
  <a style="text-decoration:none" href="#use"><div class="caq"><span class="plus">+</span>What do mouthrinses do?</div></a>
  <a style="text-decoration:none" href="#alcoholfree"><div class="caq"><span class="plus">+</span>Should I look for an alcohol-free mouthrinse?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What are mouthrinses?</span>
  <div class="contentP" id="mouthrinse">
    <p>
      Mouthrinses (or mouthwashes) are meant to be used as an adjunct to toothbrushing and flossing, and not as a replacement. They can be helpful in reaching areas not easily accessed by a toothbrush, thus aiding in daily oral hygiene. Mouthrinses are not meant to be swallowed.
    </p>
    <p>
      Mouthrinses can be classified broadly into two types - cosmetic and therapeutic. Cosmetic mouthrinses may help to control bad breath temporarily and leave behind a pleasant taste, but have no applicable effects beyond the temporary benefits.
    </p>
    <p>
      Some active ingredients that may be used in therapeutic mouthrinses are:
      <ol>
        <li>Fluoride</li>
        <li>Chlorhexidine gluconate</li>
        <li>Cetylpyridinium chloride</li>
        <li>Essential oils</li>
        <li>Peroxides</li>
      </ol>
    </p>
    <p class="p1image">
      <img src="/images/mouthrinse2.jpg" height=200/> &nbsp; <img src="/images/mouthrinse1.jpeg" height=200/><br />
      <span class="imgcredits">
        <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/ajay_suresh/51013601457">Picture</a> by ajay_suresh (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
      </span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What do mouthrinses do?</span>
  <div class="contentP" id="use">
    <p><u>Reduce Decay</u></p>
    <p>
      Fluoride mouthrinses can help to release fluoride ions, which promotes strengthening of teeth through remineralization. This can be useful for people with high risk of decay, or suffer from dry mouths. A Cochrane study has shown that regular use of fluoride mouthrinse reduces tooth decay in children, regardless of exposure to other sources of fluoride.
      <br /><br />
      An example of a fluoride mouthrinse is Fluorinze by Pearlie White.
    </p>
    <p><u>Plaque Control</u></p>
    <p>
      Chlorhexidine mouthrinses may be prescribed to reduce plaque and gum inflammation. As chlorhexidine is an antimicrobial agent, it will help to reduce the bacterial load in our mouths. They are typically given to patients with periodontitis (severe gum issues), or after an oral surgery (such as wisdom teeth removal) to help the patient lower the amount of bacteria, and reduce the risk of infection.
    </p>
    <p>
      Do take note that long-term usage of chlorhexidine mouthrinse can cause staining of teeth, the tongue and cause altered taste. As a rule of thumb, do not use a chlorhexidine mouthrinse for more than 7-10 days consecutively. 
    </p>
    <p><u>Reduce Bad Breath</u></p>
    <p>
      Other antimicrobials in mouthrinse formulations can include cetylpyridinium chloride, chlorine dioxide and essential oils (eg. eucalyptol, menthol, thymol, and methyl salicylate). These may be effective for long-term control of bad breath.
    </p>
    <p><u>Tooth Whitening</u></p>
    <p>
      Mouthrinses can help to contribute to extrinsic stain reduction when carbamide peroxide or hydrogen peroxide are amongst the active ingredients. It is safe to be used at low concentrations (1.5-2%), and may cause gingival irritation and/or increased temporary dentinal sensitivity.
    </p>
    <p>
      They are typically used twice a day for around 60 seconds each, and can take up to 3 months to see a 1 or 2 shade improvement in tooth color.
    </p>
    <p>
      Generally, these are not as effective as carbamide peroxide (10-15%) or hydrogen peroxide (35-38%) gels which your dentist may be able to prescribe for you.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Should I look for an alcohol-free mouthrinse?</span>
  <div class="contentP" id="alcoholfree">
    <p>
      Mouthrinses may also include alcohol in them. The alcohol destroys both the good and bad bacteria, and may cause an imbalance of bacteria to occur. Alcohol-free mouthrinses targets more bad bacteria than good, which creates a favourable balance to avoid further complications or bad breath.
    </p>
    <p>
      For people with dry mouths, or low salivary flow due to certain medications, previous radiation therapies or systemic diseases, they may benefit more from using alcohol-free mouthrinses since alcohol can have a dehydrating effect.
    </p>
    <p>
      There have also been concerns that using alcohol mouthrinse may lead to an increased risk of oral cancer, although the research on this is controversial and has yet to have any concrete links established.
    </p>
    <p>
      By using these alcohol-free mouthrinses, the oral environment will have more saliva flow, which helps to buffer the teeth from acid attacks. 
    </p>
  </div>
  `;

  static readonly NIGHTGUARD = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#nightguard"><div class="caq"><span class="plus">+</span>What is a night guard?</div></a>
  <a style="text-decoration:none" href="#needguard"><div class="caq"><span class="plus">+</span>Do I need a night guard?</div></a>
  <a style="text-decoration:none" href="#type"><div class="caq"><span class="plus">+</span>What are the types of night guards?</div></a>
  <a style="text-decoration:none" href="#made"><div class="caq"><span class="plus">+</span>How is a night guard made?</div></a>
  <a style="text-decoration:none" href="#last"><div class="caq"><span class="plus">+</span>How long do night guards last?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What is a night guard?</span>
  <div class="contentP" id="nightguard">
    <p class="p1image">
      <img src="/images/nightguard.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:F%C3%A9rula_descarga.JPG">Nightguard</a> by Propdental (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>)</span>
    </p>
    <p>
      Night guards have many names. They are also called mouthguards, occlusal guards, dental guards, bite splints, or nocturnal bite plates.<br /><br />
      They are retainer-like soft or hard plastic pieces that cover either the top or bottom set of teeth.<br /><br />
      Night guards are popular treatments for sleep bruxism by cushioning your teeth from the force of clenching and preventing the teeth from grinding together, preventing headaches, damaged teeth and fractured fillings.
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/n430zHShK0U" title="Occlusal Guards" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <span class="eduTemplateHTMLContainer">Do I need a night guard?</span>
  <div class="contentP" id="needguard">
    <p>
      A night guard helps to prevent damage to teeth and restorations and provides relief to those who grind their teeth in their sleep.
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/EiqFnzRSj2A" title="Bruxism (Clenching and Grinding)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>
      Teeth grinding and clenching, also known as bruxism, is a painful, destructive and common condition.
      <br /><br />
      Regular grinding and clenching of your teeth while you sleep can result in:
      <ul>
        <li>Fatigue</li>
        <li>Face pain</li>
        <li>Jaw soreness</li>
        <li>Headaches when you wake up</li>
        <li>Chipped or cracked teeth</li>
        <li>The noise from nightly teeth grinding can disturb your sleeping partner.</li>
        <li>Wearing away tooth enamel and can cause broken teeth and tooth loss.</li>
      </ul>
      Please consult your dentist for an evaluation on the need for a night guard if you find yourself suffering from the above symptoms.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What are the types of night guards?</span>
  <div class="contentP" id="type">
    <p>
      If your dentist has confirmed that bruxism is the cause of your dental problems, the next step is to review which options may help to alleviate your symptoms.<br />
      Your choices include:
      <ul>
        <li>an over-the-counter night guard from a pharmacy, or a product from an online company</li>
        <li>a bespoke, custom-made night guard made by your dentist and a dental laboratory</li>
      </ul>
      The types of night guards available differ in comfort, cost, safety and durability.<br />
      Over-the-counter and online products are typically cheaper and faster to make.
      However, they are:
      <ul>
        <li>Not made to fit your teeth properly, which can cause tissue damage and teeth movement</li>
        <li>Made of thinner materials, which may not withstand the forces of grinding and clenching</li>
      </ul>
      Getting a custom-fitted night guard from your dentist will provide the safest, most accurate fit since it is made specifically for your teeth.<br />
      Night guards can’t always stop patients from grinding but they protect the teeth and restorations from the unconscious forces exerted. The wear that occurs on your night guard would have taken place on your teeth. Wearing a guard will preserve healthy tooth structure.<br />
      Before deciding which type of night guard is best for you, discuss your options with your dentist. Then, you can feel confident about making the right choice for your dental needs.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How is a night guard made?</span>
  <div class="contentP" id="made">
    <p>
      Customised guards tend to be of a higher quality and are custom-made to your teeth. The process typically requires at least 2 separate visits.
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/R12FYIeKVyw" title="Night Guard" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <span class="eduTemplateHTMLContainer">How long do night guards last?</span>
  <div class="contentP" id="last">
    <p>
      Night guards typically last 1-5 years, depending on the severity of tooth grinding as well as frequency of use. You will need to consider a new one when you have begun to wear through the biting surface.
    </p>
  </div>
  `;

  static readonly RCT = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#rct"><div class="caq"><span class="plus">+</span>What is Root Canal Treatment (RCT)?</div></a>
  <a style="text-decoration:none" href="#require"><div class="caq"><span class="plus">+</span>How do I know if I require RCT?</div></a>
  <a style="text-decoration:none" href="#causes"><div class="caq"><span class="plus">+</span>What are some common causes that may necessitate RCT?</div></a>
  <a style="text-decoration:none" href="#performed"><div class="caq"><span class="plus">+</span>How is RCT performed?</div></a>
  <a style="text-decoration:none" href="#expectafterrct"><div class="caq"><span class="plus">+</span>What can I expect after RCT?</div></a>
  <a style="text-decoration:none" href="#treatmentoption"><div class="caq"><span class="plus">+</span>Are there any other treatment options I can consider?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What is Root Canal Treatment (RCT)?</span>
  <div class="contentP" id="rct">
    <p>
      Root canal treatment (or endodontic treatment) deals with the removal of the infected or damaged nerve (or pulp) in order to save/preserve the tooth.
    </p>
    <p class="p1image">
      <img src="/images/toothanatomy.png" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://freesvg.org/tooth-anatomy-by-paul-brennan">"Tooth Anatomy"</a> by Paul Brennan (<a target="_blank" href="https://creativecommons.org/licenses/publicdomain/">Public Domain</a>)</span>
    </p>
    <p>
      The pulp (or nerve) of the tooth is the soft tissue that resides in the root canal space, below the crown. It provides sensation and blood supply to the tooth, especially in the tooth’s formative stages.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How do I know if I require RCT?</span>
  <div class="contentP" id="require">
    <p>
      If you experience the following signs and symptoms, you may require RCT. Please consult your dentist for evaluation.
      <ol>
        <li>Does your toothache affect daily activities?</li>
        <li>Is the tooth discolored or “darkened”?</li>
        <li>Is there a “pimple” on the gums? (Refer to Sinus Tract picture under Tooth Decay)</li>
        <li>Is there spontaneous and sharp pain, especially when sleeping at night?</li>
        <li>Is there lingering pain (>30 second) to cold drinks?</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What are some common causes that may necessitate RCT?</span>
  <div class="contentP" id="causes">
    <p class="p1image">
      <img src="/images/rct1.jpg" height=300/><br />
      <span class="imgcredits">
        <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729829482/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
      </span>
    </p>
    <p>
      The dental pulp or nerve can be injured or damage by the following conditions:
      <ul>
        <li>Deep decay</li>
        <li>Dislodged fillings left unattended for too long</li>
        <li>Cracked tooth</li>
        <li>Trauma</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How is RCT performed?</span>
  <div class="contentP" id="performed">
    <p class="p1image">
      <img src="/images/rct2.jpg" height=300/><br />
      <span class="imgcredits">
        <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729829477/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
      </span>
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/0s35QCFg7p0" title="RCT" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>
      RCT will generally take two appointments. The first involves the removal of the nerve and cleaning of the canal, while the second involves filling up the canals with an inert material.
      <br /><br />
      Before, during and after the procedure, x-rays will be required at certain stages to verify that things are going as planned.
      <br /><br />
      Local anaesthesia will be administered to make sure it is a painless process.
      <br /><br />
      A rubber sheet will be used to isolate the tooth from saliva and other fluids. A metal clamp will be used to secure the rubber dam on the tooth during the procedure.
      <br /><br />
      An opening will be made through the top of the tooth to access the nerve, and then it is removed. The canals are cleaned and shaped with small instruments, irrigated with anti-microbial solutions. A medicament is usually left in the canals, and then the cavity is closed with a temporary filling in preparation for the root-fill the next visit.
      <br /><br />
      At subsequent visits, the temporary filling and intra-canal medicament is removed. The root canals are filled with an inert material (Gutta-percha) and a filling is placed to seal the cavity.
      <p class="p1image">
        <img src="/images/rctPA.jpeg" height=300/><br />
        <span class="imgcredits">
          <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Root_canal_treatment.jpg">Picture</a> by Coronation Dental Specialty Group (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>)</span>
        </span>
      </p>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What can I expect after RCT?</span>
  <div class="contentP" id="expectafterrct">
    <p>
      The area of treatment may be sensitive for a few days. Take painkillers such as Panadol if necessary.
      <br /><br />
      A crown may be required to protect your tooth after RCT. Your dentist will advise you accordingly.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Are there any other treatment options I can consider?</span>
  <div class="contentP" id="treatmentoption">
    <p>
      When the tooth is too severely broken down, or if there are cost constraints, extraction may be the preferred option. This may be followed by replacement options of the missing space eventually, such as a denture, bridge, or dental implant. Speak to your dentist to find out more!
    </p>
  </div>
  `;

  static readonly SENSITIVITY = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#sensitive"><div class="caq"><span class="plus">+</span>Why do I have sensitive teeth?</div></a>
  <a style="text-decoration:none" href="#causes"><div class="caq"><span class="plus">+</span>What are some causes?</div></a>
  <a style="text-decoration:none" href="#prevent"><div class="caq"><span class="plus">+</span>What can I do to treat or prevent this?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">Why do I have sensitive teeth?</span>
  <div class="contentP" id="sensitive">
    <p>
      When the inner layer of the tooth, dentine, is exposed, this may lead to discomfort, sensitivity and even pain, especially on exposure to thermal stimuli.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What are some causes?</span>
  <div class="contentP" id="causes">
    <p>
      Common causes of dentine exposure could be gum recession, overzealous toothbrushing, fractured teeth, or wear due to grinding.
      <br /><br />
      Gum recession and overzealous toothbrushing may lead to wearing down of the enamel and stripping away at the gums, which exposes the root of the tooth, which is not covered by enamel. Since dentine is made up of tiny tubules and exposed to any oral intake, chemical or thermal stimuli may flow through the tubules to affect the nerve in the tooth, and lead to a sensitive or uncomfortable sensation.
      <br /><br />
      Trauma leading to dental fractures, frequently taking acidic food and possibly grinding of teeth which may lead to wearing down of teeth are all possible factors. The underlying dentine is exposed as the enamel has been worn down or broke off, which leads to a more direct pathway of any stimuli towards the nerve within the tooth.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What can I do to treat or prevent this?</span>
  <div class="contentP" id="prevent">
    <p>
      <ul>
        <li>Use a soft-bristled toothbrush and gentle strokes to prevent gum recession and abrading your teeth.</li>
        <li>Fillings can be done to cover up deeper cavities with exposed dentine.</li>
        <li>Topical fluoride can be applied by your dentist on your teeth during routine appointments.</li>
        <li>Switch to toothpaste for sensitive teeth like Sensodyne or Colgate Sensitive Pro-Relief.</li>
        <li>For teeth that are extra sensitive, consider coating a thin layer of toothpaste for sensitive teeth directly after brushing.</li>
        <li>If you are aware of night grinding habits, please consult your dentist to find out if a customized nightguard can be made for you.</li>
        <li>Avoid chewing on ice, shells and bones to prevent unnecessary fracturing of teeth.</li>
      </ul>
    </p>
  </div>
  `;

  static readonly TMD = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#tmd"><div class="caq"><span class="plus">+</span>What is TMD?</div></a>
  <a style="text-decoration:none" href="#make"><div class="caq"><span class="plus">+</span>What makes up the TMJ?</div></a>
  <a style="text-decoration:none" href="#get"><div class="caq"><span class="plus">+</span>Why do I get TMD?</div></a>
  <a style="text-decoration:none" href="#have"><div class="caq"><span class="plus">+</span>How do I know if I have TMD?</div></a>
  <a style="text-decoration:none" href="#expect"><div class="caq"><span class="plus">+</span>What will I expect during my dental visit?</div></a>
  <a style="text-decoration:none" href="#treat"><div class="caq"><span class="plus">+</span>How can I treat my TMD?</div></a>
  <a style="text-decoration:none" href="#recover"><div class="caq"><span class="plus">+</span>What I recover from TMD?</div></a>
  <a style="text-decoration:none" href="#funfact"><div class="caq"><span class="plus">+</span>Additional Funfact</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What is TMD?</span>
  <div class="contentP" id="tmd">
    <p>
      Temporomandibular Joint Disorder (TMD) is a group of conditions that cause pain and loss of normal function to the jaw joint, which is known as the temporomandibular joint (TMJ), as well as the facial muscles for chewing.
      <br /><br />
      It is a common cause of non-tooth related facial pain and affects more females than males.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What makes up the TMJ?</span>
  <div class="contentP" id="make">
    <p>
      The TMJ is the joint that connects the lower jaw with the rest of the head. Situated on each side of the head, just in front of the ears, it is a flexible joint that permits movements side to side and up and down. This joint enables us to perform jaw movement and functions like eating, speaking and yawning.
      <br /><br />
      Cushion in between the joint ball (condyle) and socket (glenoid fossa) is a soft disc, which serves to cushion stress and permit smooth jaw movements. This soft disc is attached to surrounding muscles and ligaments which controls its position and movement.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Why do I get TMD?</span>
    <div class="contentP" id="get">
    <p>
      TMD is caused by numerous factors and it is often difficult to pinpoint the origins. As a result, it may be frequently under or mis-diagnosed. Your pain may be contributed by a combination of factors, such as genetics, habits, arthritis or jaw injury. People with jaw pain are often associated with clenching or tooth grinding habits (bruxism), although many people who habitually clench or grind their teeth may never develop TMD.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How do I know if I have TMD?</span>
  <div class="contentP" id="have">
    <p>
      Signs and symptoms of TMD includes:
      <ul>
        <li>Jaw pain</li>
        <li>Jaw clicking and popping</li>
        <li>Ear pain / earache</li>
        <li>Headaches</li>
        <li>stiff/ sore jaw muscles, neck and shoulder muscles</li>
        <li>Pain in the temple area</li>
        <li>Locking of the jaw joint</li>
        <li>May cause bite and teeth problems (sore and cracked teeth)</li>
      </ul>
      If left alone, the pain symptoms may worsen, and the chronic inflammation of the joint may result in irreversible changes within the joint. Overall, TMD can reduce your quality of life!
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What will I expect during my dental visit?</span>
  <div class="contentP" id="expect">
    <p>
      Your doctor / dentist will take a detailed pain history and symptoms of your condition and examine your jaw to identify:
      <ul>
        <li>Sites / location of pain</li>
        <li>Any reduction of jaw range of motion</li>
        <li>Any click/ pop of the jaw joint</li>
      </ul>
      If your doctor / dentist suspects that you have a problem with your joints, you may need:
      <ul>
        <li>Dental x-rays to examine your teeth and jaw</li>
        <li>CT scan to provide a detailed image of the bones involved in the joint</li>
        <li>MRI to investigate the joint’s disk and surrounding soft tissue</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How can I treat my TMD?</span>
  <div class="contentP" id="treat">
    <p>
      <p><u>1. Therapies</u></p>
      TMD often responds to home remedies and self-care. Such remedies include placing an ice-pack over the affected joint and muscles, jaw stretching exercises and stress reduction.
      Apart from self-care, behavioural modification of harmful habits, such as biting of hard foods like nuts, chewing on ice cubes or chewing gum, should be minimised. 

      <p><u>2. Removable Oral Appliances</u></p>
      Some TMD symptoms can be relieved with the use of a bite splint. A bite splint is especially useful for patients who grind their teeth during sleep.

      <p><u>3. Medications</u></p>
      Certain anti-inflammatory drugs and muscle relaxants may be helpful in reducing the pain associated with TMD.
      Botox injection is considered an alternate method of treatment for TMD. When injected into the facial muscles afflicted with soreness and discomfort, BOTOX relieves TMJ and jaw tensions for such patients. However, the effects of BOTOX injections do not last permanently. Please consider your medical doctor/ dentist for more information.  

      <p><u>4. Surgery</u></p>
      Surgery is typically not the first option recommended to patients with TMD. However, it may be considered for patients who do not respond well to non-surgical treatment. 
      Surgery may range from simple flushing of the inflamed joints by inserting a small needle to insert and release the fluids (arthrocentesis), or by inserting a small scope into the joint to examine and treat the joint (arthroscopy).
      In more severe cases, an open joint surgery may be required.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What I recover from TMD?</span>
  <div class="contentP" id="recover">
    <p>
      The prognosis of TMD is normally good for people who respond with self-care and home remedies. It is important to be consistent and compliant with the advice given to you to ensure that the symptoms do not recur.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Additional Funfact</span>
  <div class="contentP" id="funfact">
    <p>
      A local study published in 2019 has shown that TMD affects one in three of military personnel! That’s pretty common isn’t it?
    </p>
  </div>
  `;

  static readonly ULCER = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#ulcers"><div class="caq"><span class="plus">+</span>What causes ulcers?</div></a>
  <a style="text-decoration:none" href="#look"><div class="caq"><span class="plus">+</span>What do ulcers look like?</div></a>
  <a style="text-decoration:none" href="#do"><div class="caq"><span class="plus">+</span>What do I need to do?</div></a>
  <a style="text-decoration:none" href="#see"><div class="caq"><span class="plus">+</span>Do I need to see a dentist?</div></a>
  <a style="text-decoration:none" href="#coldsore"><div class="caq"><span class="plus">+</span>What are cold sores?</div></a>
  <a style="text-decoration:none" href="#coldsorelook"><div class="caq"><span class="plus">+</span>What do cold sores look like?</div></a>
  <a style="text-decoration:none" href="#howtotreat"><div class="caq"><span class="plus">+</span>How to treat / manage cold sores?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What causes ulcers?</span>
  <div class="contentP" id="ulcers">
    <p class="pheader">Common Ulcer (Aphthous Stomatitis)</p>
    <p>
      <ul>
        <li>No definitive cause for ulcers has been concluded but is most commonly associated with trauma, stress, hormonal influences, immunologic factors, nutritional deficiencies and smoking cessation</li>
        <li>There is also a genetic predisposition</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What do ulcers look like?</span>
  <div class="contentP" id="look">
    <p class="p1image">
      <img src="/images/aphthousulcer.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Aphthe_Unterlippe.jpg">Aphthous Ulcer</a> by Pfiffner Pascal (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>)</span>
    </p>
    <p>
      <ul>
        <li>Usually appear yellow-white, encircled by a red halo</li>
        <li>Can range from 3-10mm in diameter </li>
        <li>Usually appears on tongue, floor of the mouth, junction between the gums & lips, soft palate</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What do I need to do?</span>
    <div class="contentP" id="do">
    <p>
      <ul>
        <li>Home remedies include salt water rinse: Dissolve 1 teaspoon of salt in half a cup of warm water, gargle for 15-30s</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Do I need to see a dentist?</span>
  <div class="contentP" id="see">
    <p>
      <ul>
        <li>Most ulcers usually heal without scarring in 7-14 days</li>
        <li>Should there be discomfort, you can see your dentist for some topical medication</li>
        <li>Dab a small amount of gel using a clean, dry finger </li>
        <li>Apply 2-3 times daily after meal, avoid eating/drinking after application</li>
        <li>Should your ulcer persist for more than 14 days, it may be advisable to obtain a consultation with your dentist</li>
      </ul>
    </p>
    <br /><br /><br />
    <p class="pheader">Cold Sores (caused by Herpes Simplex Virus)</p>
  </div>
    <span class="eduTemplateHTMLContainer">What are cold sores?</span>
  <div class="contentP" id="coldsore">
    <p>
      Cold sores are a symptom of human herpes virus, with herpes simplex virus (HSV) being the most common.<br />
      HSV can be further classified into two different types:
      <ul>
        <li>HSV-1: spread predominantly through infected saliva or active perioral lesions. Affects oral, facial and ocular areas most frequently</li>
        <li>HSV-2: spread through sexual contact. Affects genital zones most frequently</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What do cold sores look like?</span>
  <div class="contentP" id="coldsorelook">
    <p class="p1image">
      <img src="/images/coldsore.jpeg" height=250/><br />
      <span class="imgcredits"><a target="_blank" href="https://en.wikipedia.org/wiki/File:Herpes(PHIL_1573_lores).jpg">Herpes Ulcer</a> by Centers for Disease Control and Prevention, United States Department of Health and Human Services (Public Domain)</span>
    </p>
    <p>
      <ul>
        <li>Initial stage: pinhead versicles</li>
        <li>Mid-stage: vesicles collapse to form small, red lesions</li>
        <li>Late stage: lesions enlarge and develop central areas of ulceration, appearing yellowish</li>
        <li>Gums are commonly swollen, red and painful</li>
        <li>Can also appear on lip/ adjacent skin</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How to treat / manage cold sores?</span>
  <div class="contentP" id="howtotreat">
    <p>
      <ul>
        <li>Restrict contact with lesions to prevent spread to other sites and people</li>
        <li>Avoid rubbing of eyes</li>
        <li>See a doctor / dentist</li>
        <li>Antiviral medications may be prescribed</li>
      </ul>
    </p>
  </div>
  `;

  static readonly WISDOM = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#wisdom"><div class="caq"><span class="plus">+</span>What is a wisdom tooth?</div></a>
  <a style="text-decoration:none" href="#impacted"><div class="caq"><span class="plus">+</span>What is an impacted wisdom tooth?</div></a>
  <a style="text-decoration:none" href="#removed"><div class="caq"><span class="plus">+</span>Why do they need to be removed?</div></a>
  <a style="text-decoration:none" href="#whentoremove"><div class="caq"><span class="plus">+</span>When should they be removed?</div></a>
  <a style="text-decoration:none" href="#grewstraight"><div class="caq"><span class="plus">+</span>What if my wisdom teeth grew out straight?</div></a>
  <a style="text-decoration:none" href="#opposingimpacted"><div class="caq"><span class="plus">+</span>My upper wisdom tooth has grown out normally, but is opposing an impacted lower wisdom tooth. Should I take it out?</div></a>
  <a style="text-decoration:none" href="#howtoremove"><div class="caq"><span class="plus">+</span>How are they removed?</div></a>
  <a style="text-decoration:none" href="#risks"><div class="caq"><span class="plus">+</span>What are the risks and potential complications?</div></a>
  <a style="text-decoration:none" href="#expectafter"><div class="caq"><span class="plus">+</span>What should I expect after the surgery?</div></a>
  <a style="text-decoration:none" href="#postsurgery"><div class="caq"><span class="plus">+</span>Post-Surgery Instructions</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">What is a wisdom tooth?</span>
  <div class="contentP" id="wisdom">
    <p>
      Our wisdom teeth, aka third molars, are the last permanent teeth to erupt, often between 16 to 21 years old or sometimes even beyond. They are located all the way at the back, behind the second molars.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What is an impacted wisdom tooth?</span>
  <div class="contentP" id="impacted">
    <p>
      Not all wisdom teeth erupt develop normally. A wisdom tooth is ‘impacted’ when it fails to fully erupt in the mouth, due to a lack of space, or when it erupts in the wrong direction.
    </p>
    <p class="p1image">
      <img src="/images/wisdom2.jpg" height=250/><br />
      <span class="imgcredits">
        <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/dental-photos/50729828847/">Picture</a> by Authority Dental (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
      </span>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Why do they need to be removed?</span>
  <div class="contentP" id="removed">
    <p>
      Impacted wisdom teeth may cause various problems. The overlying gums can become swollen and painful, and pus may develop in the region.
      <p class="p1image">
        <img src="/images/pericoronitis.jpeg" height=250/><br />
        <span class="imgcredits">
          <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:48_clinical_pericornitis.jpg">Picture</a> by Coronation Dental Specialty Group (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>)</span>
        </span>
      </p>
      The gap between impacted wisdom teeth and the adjacent second molars are impossible to clean. Food and bacteria can accumulate at the area and cause decay and gum disease at the adjacent tooth. If left undetected, the decay or gum disease may end up compromising the adjacent tooth, necessitating the extraction of the second molar as well.
      <br /><br />
      In extremely rare cases, cysts and/or tumours may form as a result of completely buried wisdom teeth. 
      <br /><br />
      It must be noted that not all wisdom teeth need to be removed. Some erupt normally and function as normal teeth.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">When should they be removed?</span>
  <div class="contentP" id="whentoremove">
    <p>
      Emergent or urgent Indications for removal would include:
      <ul>
        <li>history of recurrent swelling and pain at the region,</li>
        <li>presence of active decay or gum disease on the wisdom tooth OR the adjacent teeth</li>
        <li>patients on treatment for braces may be advised by their Dentist for removal of wisdom teeth to facilitate straightening of their other teeth</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What if my wisdom teeth grew out straight?</span>
  <div class="contentP" id="grewstraight">
    <p>
      Congratulations, there is absolutely no need to remove the wisdom teeth at this point of time! However, you must care for them as much as your other teeth, as they are equally susceptible to decay and gum disease. Regular dental check ups are recommended.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">My upper wisdom tooth has grown out normally, but is opposing an impacted lower wisdom tooth. Should I take it out?</span>
  <div class="contentP" id="opposingimpacted">
    <p>
      We often advocate removal of the upper wisdom tooth for multiple reasons:
      <ol>
        <li>if it does not have an opposing tooth to bite on, it will often grow lower over time and eventually start biting on your opposing gum / cheek. At that point in time, it will cause traumatic ulcerations that will necessitate expeditious removal of the wisdom tooth.</li>
        <li>as the upper wisdom tooth continues to further erupt, it will create a "step" that makes it easier for food to get trapped and harder to clean, placing both the wisdom teeth and the adjacent second molar at increased risk of decay and gum disease.</li>
        <li>there often is insufficient space for the toothbrush to properly access and clean the upper wisdom teeth, resulting in a higher risk of decay and gum disease.</li>
      </ol>
      However, as this is an elective treatment, the decision to remove or not is yours to make.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">How are they removed?</span>
  <div class="contentP" id="howtoremove">
    <p>
      1. Surgical removal of wisdom teeth - Wisdom teeth that are impacted often require surgery for removal. The gums are reflected to expose the underlying impacted wisdom tooth. The wisdom tooth is then cut into sections and removed piece by piece. Stitches are then placed to replace the gums to its original position. There may be a hole at the gums where the impacted wisdom tooth was previously. This hole will slowly close with time.
    </p>
    <p>
      2. Simple extraction of wisdom teeth - In cases of fully erupted wisdom teeth, a simple extraction (no surgery) can be attempted. If difficulty is encountered during the extraction, it can be converted into a surgery if necessary. Upper wisdom teeth are commonly removed via simple extractions.
    </p>
    <p>There are 2 modes of removing wisdom teeth: </p>
    <p>
      1. Local anaesthesia. You will be awake during the entire surgery. The site is numbed with an injection and the  surgery is often painless. You will still, however, be able to feel the pressure, drilling and cracking of the teeth.
    </p>
    <p>
      2. General anaesthesia. You will be placed into a medically-induced coma, and the wisdom teeth will be removed while you are asleep. However, there are additional risks involved with general anaesthesia and is not suitable for all. Please speak to your Dentist for evaluation.
    </p>
    <iframe width="100%" height="300" src="https://www.youtube.com/embed/AqVBtKEOSOU" title="Wisdom Tooth Removal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <span class="eduTemplateHTMLContainer">What are the risks and potential complications?</span>
  <div class="contentP" id="risks">
    <p>
      Although wisdom tooth surgery is a common procedure, there are multiple risks and potential complications involved determined on a case-by-case basis.
      <br />
      Some risks include:
      <ul>
        <li><u>Risk of numbness</u> to the lower lip, chin, and tongue including taste. Wisdom teeth are often located in close proximity to 2 nerves that supply sensation to the abovementioned regions. This nerve may be bruised or injured as a result of the surgery. Numbness (if any) is often temporary, but there are extremely rare cases of permanent numbness. No 2 surgeries are the same. Your friend’s experience may not be similar to your own experience.</li>
        <li><u>Risk of wound infection</u>. The open wound resulting from the surgery is left to heal naturally, and may occasionally become infected during the healing process (up to a month after surgery). You may experience pain, swelling, fever and/or pus formation at the wound site.</li>
        <li><u>Jaw Pain</u> the jaw joint may ache due to the long period of time spent on opening your mouth during surgery. While this discomfort will reduce over time, medication and physical therapy may be required for some patients to relieve the ache</li>
      </ul>
      This list is by no means exhaustive. Please consult your Dentist for the risks pertaining to your case.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What should I expect after the surgery?</span>
  <div class="contentP" id="expectafter">
    <p>
      1. Mild bleeding
      <ol type="a">
        <li>the surgical site will often have active bleeding immediately after the surgery</li>
        <li>it is well controlled by biting hard on a gauze</li>
        <li>minimise rinsing and spitting as it will dilute the blood (making it seem more than it actually is), and also prevent the blood from clotting</li>
        <li>try your best to swallow your saliva </li>
      </ol>
      2. Mild-moderate facial swelling
      <ol type="a">
        <li>Swelling on the face is a normal response to the surgery. The extent of which depends on the difficulty of the surgery as well as the individual immune response. Peak swelling typically occurs 72 hours after the surgery.</li>
        <li>Bruising may also be observed</li>
        <li>Swelling should subside after 1 week</li>
      </ol>
      3. Limited jaw opening
      <ol type="a">
        <li>Due to a result of the swelling</li>
        <li>Minimise your mouth opening as much as possible</li>
        <li>Support your jaw when yawning</li>
      </ol>
      4. Pain
      <ol type="a">
        <li>Natural body response post surgery</li>
        <li>Managed by painkillers prescribed by dentist</li>
        <li>An improving trend should be observed post op till up to 1-2 weeks depending on difficulty of surgery</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Post-Surgery Instructions</span>
  <div class="contentP" id="postsurgery">
    <p>
      <ul>
        <li><u>Avoid frequent rinsing or spitting</u> as this may dislodge the blood clot and cause bleeding to continue.</li>
        <li><u>Avoid sucking through a straw vigorously or stifling sneezes</u>, especially after the extraction of an upper back tooth.</li>
        <li><u>Avoid exercise or vigorous activities</u> for 1-2 days.</li>
        <li><u>Avoid disturbing the wound</u> with your tongue, finger, or a utensil. This may cause irritation, infection, and/or bleeding.</li>
        <li><u>Avoid smoking and alcohol</u> for 1-2 days, preferably up to 5 days as this increases the risk of infection or poor wound healing.</li>
        <li><u>Go on a soft diet</u> and chew food using the unaffected side for 24 hours.</li>
        <li><u>Take medications as directed</u> - if antibiotics are prescribed, the full course of antibiotics must be completed, while painkillers can be taken as needed until pain resolves.</li>
        <li><u>Expect mild oozing</u> - If bleeding occurs, bite hard on gauze for 30 minutes. Remove the gauze after that and repeat if necessary. If bleeding persists and is profuse in nature, return to dentist in the day or a hospital A&E after office hours</li>
      </ul>
    </p>
  </div>
  `;

  static readonly XRAY = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#xray"><div class="caq"><span class="plus">+</span>Why are dental x-rays taken?</div></a>
  <a style="text-decoration:none" href="#types"><div class="caq"><span class="plus">+</span>What are the different types of dental x-rays?</div></a>
  <a style="text-decoration:none" href="#radiation"><div class="caq"><span class="plus">+</span>What are the different types of dental x-rays?</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer">Why are dental x-rays taken?</span>
  <div class="contentP" id="xray">
    <p>
      X-rays are routinely used for diagnosis of oral conditions, and they are an essential part of dental care which allow dentists to:
      <ol>
        <li>Diagnose decay</li>
        <li>Detect presence and extent of tooth decay</li>
        <li>Examine the roots and bone supporting the tooth</li>
        <li>Check the status of existing restorations such as fillings and crowns</li>
        <li>Determine if periodontal disease is an oral care issue</li>
        <li>See the status of developing teeth</li>
      </ol>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">What are the different types of dental x-rays?</span>
  <div class="contentP" id="types">
    <p>
      <u>Intra-oral – Periapical</u>
      <div class="p1image">
        <img src="/images/PA.jpeg" height=250/><br />
        <span class="imgcredits"><a target="_blank" href="https://www.flickr.com/photos/woodhead/8317014004">Picture</a> by Jason Woodhead (<a target="_blank" href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>)</span>
      </div>
      <br />
      Provides a view of the entire tooth, from the crown to the bone that helps to support the tooth.
    </p>
    <p>
      <u>Intra-oral – Bitewings</u>
      <div class="p1image">
        <img src="/images/BW.jpeg" height=250/><br />
        <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:Tooth_decay_and_abscess_xray.png">Picture</a> by Coronation Dental Specialty Group (<a target="_blank" href="https://creativecommons.org/licenses/by/3.0/">CC BY-SA 3.0</a>)</span>
      </div>
      <br />
      Offers a visual of both the lower & upper posterior teeth. This type of X-ray is useful to detect the presence and extent of decay on back teeth.
    </p>
    <p>
      <u>Extra-oral – Orthopantomogram (OPG)</u>
      <div class="p1image">
        <img src="/images/OPG.jpeg" style="width:85%"/><br />
        <span class="imgcredits"><a target="_blank" href="https://commons.wikimedia.org/wiki/File:X-ray_of_all_32_human_teeth.jpg">Picture</a> by Ruhrfisch (<a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>)</span>
      </div>
      <br />
      Offers a visual of both the lower & upper posterior teeth. This type of X-ray is useful to detect the presence and extent of decay on back teeth.
    </p>
  </div>
  <span class="eduTemplateHTMLContainer">Should I be worried about the radiation from dental x-rays?</span>
  <div class="contentP" id="radiation">
    <p>
      Dental x-rays have extremely low radiation doses. In fact, the amount of radiation from dental x-rays is comparable to naturally occurring background radiation in the environment.
    </p>
  </div>
`;

  static readonly WHITENING = `
  <span class="eduTemplateHTMLContainer">Frequently Asked Questions</span>
  <a style="text-decoration:none" href="#1"><div class="caq"><span class="plus">+</span>What is teeth whitening?</div></a>
  <a style="text-decoration:none" href="#2"><div class="caq"><span class="plus">+</span>Do teeth whitening products really work?</div></a>
  <a style="text-decoration:none" href="#3"><div class="caq"><span class="plus">+</span>Will teeth whitening work for everybody?</div></a>
  <a style="text-decoration:none" href="#4"><div class="caq"><span class="plus">+</span>How does a dentist whiten my teeth?</div></a>
  <a style="text-decoration:none" href="#5"><div class="caq"><span class="plus">+</span>Are there side effects to teeth whitening?</div></a>
  <a style="text-decoration:none" href="#6"><div class="caq"><span class="plus">+</span>Am I able to receive teeth whitening at the various Dental Centres in SAF?</div></a>
  <br/>
  <br />
  <span class="eduTemplateHTMLContainer">What is teeth whitening?</span>
  <div class="contentP" id="1">
  <p>
    Teeth whitening is the lightening of the colour of your teeth through the
    application of a safe and medically approved gel. It is fast, relatively
    easy and minimally invasive, requiring no local anaesthetic or any removal
    of tooth substance.
  </p>
  </div>
  <span class="eduTemplateHTMLContainer"
  >Do teeth whitening products really work?</span
  >
  <div class="contentP" id="2">
  <p>
    In general, yes! – provided they are used in the right cases and in the
    right way. In some cases, teeth discolouration cannot be managed by teeth
    whitening alone.
  </p>
  <p>
    Over the counter products have much lower concentration of the active agent
    so they take a longer time to work.
  </p>
  <p>
    Professionally used products, on the other hand, have higher concentrations
    so the results can be seen faster – and may last longer.
  </p>
  </div>
  <span class="eduTemplateHTMLContainer"
  >Will teeth whitening work for everybody?</span
  >
  <div class="contentP" id="3">
  <p>No!</p>

  <ul>
    <li>Teeth Whitening products work best on natural teeth</li>
    <li>
      People with deep stains caused by antibiotics may see very little
      enhancement.
    </li>
    <li>
      Teeth with uneven colours such as opaque white spots that are irregular
      cannot be changed to a uniform colour.
    </li>
    <li>
      Sensitivity is a common side effect and those with gum recession, exposed
      roots, worn out teeth or faulty fillings may be more severely affected
    </li>
  </ul>
  <p>
    For these reasons, it is best to visit a dentist for a thorough evaluation
    to assess which method is best for you and your needs.
  </p>
  <p>
    Sometimes, all you may need is a good, professional polishing to have
    brighter teeth
  </p>
  </div>
  <span class="eduTemplateHTMLContainer"
  >How does a dentist whiten my teeth?
  </span>
  <div class="contentP" id="4">
  <p>There are two main ways as to how teeth whitening can be done:</p>
  <h3>1. Chairside Teeth Whitening</h3>
  <p>
    Chairside teeth whitening is thoroughly done at your dental office by your
    dentist, where a strong concentration of whitening gel is used on your teeth
    to achieve exceptionally quicker results. This is useful if you are looking
    for results on short notice.
  </p>
  <h3>2. Home-Based Teeth Whitening Treatment</h3>
  <p>
    Home-based teeth whitening involves the instructed self-placement of
    whitening gel into customised trays made specifically for your mouth, which
    are then placed over your teeth. The gels are of lower concentration (as
    compared to chairside teeth whitening).
  </p>
  <p>
    Depending on the nature and severity of your tooth discolouration, the
    duration of a treatment usually lasts from 2 to 6 weeks. It may take up to 6
    months for stubborn and intrinsic stains. Chairside and home-based tooth
    whitening may sometimes be used in combination to achieve enhanced and
    sustainable results.
  </p>
  </div>
  <span class="eduTemplateHTMLContainer"
  >Are there side effects to teeth whitening?
  </span>
  <div class="contentP" id="5">
  <p>
    Tooth sensitivity is a common side effect after bleaching. There may also be
    some gum irritation. The results of the whitening may be unpredictable and
    uneven and there is a tendency for the tooth colour to relapse, requiring
    regular bleaching to maintain the desired tooth colour.
  </p>
  </div>
  <span class="eduTemplateHTMLContainer"
  >Am I able to receive teeth whitening at the various Dental Centres in SAF?
  </span>
  <div class="contentP" id="6">
  <p>
    Unfortunately, no. The dental centres in the SAF focuses on delivering high
    quality essential dental services needed to maintain the operation readiness
    of our soldiers. As whitening remains a non-essential dental service that is
    most of the time a purely aesthetic issue, we are unable to provide such
    services at the present time.
  </p>
  </div>
  `;
}