import { configureStore } from "@reduxjs/toolkit";
import eduContentReducer from "./eduContent";
import adminReducer from "./admin";
import chatbotReducer from "./chatbot";
import nodeReducer from "./nodesContent";

const store = configureStore({reducer : {
  eduContent : eduContentReducer,
  admin: adminReducer,
  chatbot: chatbotReducer,
  node: nodeReducer
}});

export default store; 
export type RootState = ReturnType<typeof store.getState>;