import { Link } from "react-router-dom";
import "./MenuDrop.css";
import { RootState } from "../../store/indexStore";
import { TokenAuth } from "../../store/admin";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

const MenuDrop = (props: { menuStatus: Dispatch<SetStateAction<boolean>> }) => {
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  return (
    <div className="menudrop">
      {loggedStatus.isLoggedIn && (
        <Link
          to="/tbadmin/dashboard"
          style={{ width: "100%" }}
          onClick={() => props.menuStatus(false)}
        >
          <Button variant="outlined" style={{ width: "100%" }}>
            DashBoard
          </Button>
        </Link>
      )}
      {loggedStatus.isLoggedIn && (
        <Link
          to="/tbadmin/dialogmap"
          style={{ width: "100%" }}
          onClick={() => props.menuStatus(false)}
        >
          <Button variant="outlined" style={{ width: "100%" }}>
            ChatBotDialogNode
          </Button>
        </Link>
      )}
      {loggedStatus.isLoggedIn && (
        <Link
          to="/tbadmin/post"
          style={{ width: "100%" }}
          onClick={() => props.menuStatus(false)}
        >
          <Button variant="outlined" style={{ width: "100%" }}>
            Post
          </Button>
        </Link>
      )}
    </div>
  );
};

export default MenuDrop;
