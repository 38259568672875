import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface chatBotNode {
  node: number;
  content:string
  modalOn: boolean;
}

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState: { node: -1, content:"",modalOn: false },
  reducers: {
    setModal: (state: chatBotNode, action: PayloadAction<chatBotNode>) => {
      return action.payload;
    },
  },
});

export default chatbotSlice.reducer;