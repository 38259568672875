import axios, { AxiosError, AxiosResponse } from "axios";
import "./PostList.css";
import Pagination from "@mui/material/Pagination";
import { Constants } from "../../../Constants";
import { EduContentDB } from "../../../model";
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const PostList = (props: {
  getlmt: (data: number) => void;
  create:boolean;
}) => {
  const [eduContent, setEduContent] = useState<EduContentDB[]>(
    Constants.DEFAULT_EDU_CONTENT
  );
  const [pgNumber, setPgNumber] = useState<number>(1);
  const [pgLimit, setpgLimit] = useState<number>(5);
  const [pg, setPg] = useState(1);

  props.getlmt(pgLimit);

  const getContent = (pgLimit: number) => {
    axios
      .get<EduContentDB[]>(Constants.EDU_CONTENT, {
        timeout: 10000,
        timeoutErrorMessage: "timeout 10 second",
      })
      .then((res) => {
        setEduContent(res.data);
        const count = res.data.length;
        // count is the number of row coming from the db
        // pgNumber is the number count in pagination
        // pgLimit is the total number of row per page
        if (count % pgLimit == 0) setPgNumber(count / pgLimit);
        else setPgNumber(Math.ceil(count / pgLimit));
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getContent(pgLimit);
  }, [props.create]);

  const hideButtonHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    title: string
  ) => {
    try {
      const response = await axios.put<{ result: number }>(
        Constants.URL_ADMINTOGGLEDISPLAY,
        {
          title,
        }
      );
      console.log("this is from hideButtonHandler ", response);
      getContent(pgLimit);
    } catch (error) {
      console.log("this is from hideButtonHandler ", error);
    }
  };

  const paginationHandler = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPg(page);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPg(1);
    setPgNumber(Math.ceil(eduContent.length / +event.target.value));
    setpgLimit(+event.target.value);
  };

  return (
    <div className="postlist">
      <h3>List of Content in Education</h3>
      <table className="adminEduCont">
        <thead>
          <tr>
            <th>No</th>
            <th>Picture</th>
            <th>Title</th>
            <th>URL</th>
            <th>Display</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {eduContent
            .slice((pg - 1) * pgLimit, pg * pgLimit)
            .map((x, index) => (
              <tr key={x.title}>
                <td>{index + 1 + (pg - 1) * pgLimit}</td>
                <td style={{ textAlign: "center" }}>
                  <img src={x.pic_url} alt="" width={"70px"} />{" "}
                </td>
                <td>{x.title} </td>
                <td>
                  {" "}
                  <Link to={x.path_var} target="_blank">
                    {x.path_var}
                  </Link>{" "}
                </td>
                <td>
                  <button onClick={(e) => hideButtonHandler(e, x.title)}>
                    {x.display ? "Hide" : "Show"}
                  </button>
                </td>
                <td>
                  <Link to={"/tbadmin/post/" + x.path_var.split("/").pop()}>
                    <button>Edit</button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <div className="rowControl">
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel>Rows</InputLabel>
                  <Select
                    value={String(pgLimit)}
                    onChange={handleChange}
                    autoWidth
                    label="Rows"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
                <Stack spacing={2}>
                  <Pagination
                    count={pgNumber}
                    variant="outlined"
                    shape="rounded"
                    page={pg}
                    onChange={paginationHandler}
                  />
                </Stack>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default PostList;
