import axios, { AxiosError } from "axios";
import { SyntheticEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigation } from "react-router-dom";
import { RootState } from "../../../store/indexStore";
import { TokenAuth } from "../../../store/admin";
import { Constants } from "../../../Constants";

// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const ChangePw = () => {
  const navigation = useNavigation();
  const [oldpw, setOldPw] = useState("");
  const [newpw, setNewPw] = useState("");

  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  const changePwHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      if (oldpw == newpw) throw new Error("old and new pw cannot be the same");
      const response = await axios.put<{ result: string }>(
        Constants.URL_ADMINCHANGEPW,
        {
          email: loggedStatus.loginNick,
          oldPw: oldpw,
          newPw: newpw,
        }
      );
      alert("Password is changed");
      console.log("change pw successful", response.data.result);
      setOldPw("");
      setNewPw("");
    } catch (error: unknown) {
      alert(`error changing password:  ${error}`);
      if (error instanceof AxiosError) {
        console.log("change pw error  ", error);
        console.log("change pw error  ", error.message);
      }
    }
  };

  return (
    <form onSubmit={changePwHandler}>
      <h3>Change Password for {loggedStatus.loginNick || ""}</h3>
      <label htmlFor="oldpw" style={{ display: "block" }}>
        Old Password
      </label>
      <input
        name="oldpw"
        id="oldpw"
        type="text"
        size={30}
        minLength={6}
        value={oldpw}
        onChange={(e) => setOldPw(e.target.value)}
        required
      />

      <label htmlFor="newpw" style={{ display: "block" }}>
        New Password
      </label>
      <input
        name="newpw"
        id="newpw"
        type="text"
        size={30}
        minLength={6}
        value={newpw}
        onChange={(e) => {
          setNewPw(e.target.value);
        }}
        required
      />
      <div className="button_submit">
        <button type="submit">
          {navigation.state === "submitting" ? "Changing" : "Change"}
        </button>
      </div>
    </form>
  );
};

export default ChangePw;
