import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EduContentDB } from "../model";
import { Constants } from "../Constants";

const intialEduContent:EduContentDB[] = Constants.DEFAULT_EDU_CONTENT;

export const eduSlice = createSlice({
  name : "educationContent",
  initialState: intialEduContent,
  reducers: {
    loadContent : (state, action:PayloadAction<EduContentDB[]>) =>{
      state = action.payload
      return state;
    }
  }
}) 

export default eduSlice.reducer;
