import React, { useEffect } from "react";
import "./LogOutPage.css";
import { useNavigate } from "react-router-dom";
import store from "../../store/indexStore";
import { adminSlice } from "../../store/admin";

const LogOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("tbau");
      store.dispatch(
        adminSlice.actions.setToken({
          loginNick: undefined,
          role: undefined,
          isLoggedIn: false,
        })
      );
      navigate("/tbadmin/login");
    }, 2000);
  }, []);

  return <div className="logoutcontainer">Logging out!!!</div>;
};

export default LogOut;
