import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Constants } from "../../../Constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/indexStore";
import { TokenAuth } from "../../../store/admin";
import "./GetUsers.css"

// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

interface AdminInfo {
  email: string;
  admin_auth: string;
}

const GetUser = () => {
  const adminDefault: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );
  const [allAdminInfo, setAllAdminInfo] = useState<AdminInfo[]>([
    {
      email: adminDefault.loginNick || "",
      admin_auth: adminDefault.role || "",
    },
  ]);

  useEffect(() => {
    axios.get<AdminInfo[]>(Constants.URL_ADMINGETALL).then((res) => {
      setAllAdminInfo(res.data);
    });
  }, []);

  const deleteHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    console.log(e.target);
    const emailtoDelete = (e.target as HTMLButtonElement).value;
    try {
      const response = await axios.delete<{ result: string }>(
        Constants.URL_ADMINDELETE,
        {
          data: { email: emailtoDelete },
        }
      );
      alert(response.data.result);
      await axios.get<AdminInfo[]>(Constants.URL_ADMINGETALL).then((res) => {
        setAllAdminInfo(res.data);
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="adminlist">
      <h3>Admin List</h3>
      {/* <ol>
        {allAdminInfo.map((x) => {
          return (
            <li key={x.email}>
              <span>{x.email}</span> : <span>{x.admin_auth}</span>{" "}
              {adminDefault.role === Constants.ADMIN_SUPER &&
                x.email !== adminDefault.loginNick && (
                  <button type="button" value={x.email} onClick={deleteHandler}>
                    Delete
                  </button>
                )}
            </li>
          );
        })}
      </ol> */}
      <table className="deleteuser">
        <tr>
          <th>no</th>
          <th>nick/email</th>
          <th>role</th>
          <th>delete?</th>
        </tr>
        <tbody>
          {allAdminInfo.map((x, index) => {
            return (
              <tr key={x.email}>
                <td>{index}</td>
                <td>{x.email}</td>
                <td>{x.admin_auth}</td>
                <td>
                  {adminDefault.role === Constants.ADMIN_SUPER &&
                    x.email !== adminDefault.loginNick? (
                      <button
                        type="button"
                        value={x.email}
                        onClick={deleteHandler}
                      >
                        Delete
                      </button>
                    ) :
                    <button disabled>Delete</button>}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GetUser;
