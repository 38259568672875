import "./PostEdit.css";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import parse from "html-react-parser";
import { HTMLReactParserOptions } from "html-react-parser/lib/types";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { IEduHeader } from "../../model";
import { Constants } from "../../Constants";
import { getContents } from "../..";
import { useSelector } from "react-redux";
import { RootState } from "../../store/indexStore";
import { useNavigate, useParams } from "react-router-dom";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const template = ``;

const PostEditor = () => {
  const param = useParams();

  const [headers, setHeaders] = useState<IEduHeader[]>([
    { id: 1, name: "Oral Health Wellness" },
    { id: 2, name: "Dental Conditions" },
    { id: 3, name: "Dental Treatment" },
  ]);
  const [header, setHeader] = useState("Oral Health Wellness");
  const [picUrl, setPicUrl] = useState("/dental_icons/dentalFFI.png");
  const [url, setUrl] = useState("/education/");
  const [title, setTitle] = useState("");

  const [content, setContent] = useState(template);

  const navigate = useNavigate();

  const contents = useSelector((state: RootState) => state.eduContent);
  const oldTitle = contents.find(
    (x) => x.path_var.split("/").pop() === param.var
  )?.title;
  const oldurl = contents.find(
    (x) => x.path_var.split("/").pop() === param.var
  )?.path_var;

  useEffect(() => {
    axios
      .get<IEduHeader[]>(Constants.EDU_CONTENT_HEADER)
      .then((res) => {
        setHeaders(res.data);

        const fetcheddata = res.data;
        getContents()
          .then((res) => {
            const editContent = contents.find(
              (x) => x.path_var.split("/").pop() === param.var
            );
            const contentheader = fetcheddata.find(
              (x) => x.id === editContent?.eduheader
            )?.name;
            setHeader(contentheader || "Oral Health Wellness");
            setPicUrl(editContent?.pic_url || "");
            setUrl(editContent?.path_var || "");
            setTitle(editContent?.title || "");
            setContent(editContent?.content_html || "");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // use for replacing <a> to Link so that request is sent to client instead of server
  const options: HTMLReactParserOptions = {
    replace(domNode: any) {
      if (
        domNode.name == "a" &&
        domNode.attribs.style === "text-decoration:none"
      ) {
        // return <HashLink to ={domNode.attribs.href}> {domNode.children[0].children[1].data}</HashLink>
        return (
          <HashLink
            to={domNode.attribs.href}
            className="caq"
            style={{ textDecoration: "none" }}
          >
            <div className="caq">
              <span className="plus">+</span>
              {domNode.children[0].children[1].data}
            </div>
          </HashLink>
        );
      }
    },
  };

  const onEditHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      const result = await axios.put(Constants.URL_ADMIN_CREATEPOST, {
        title: title,
        pic_url: picUrl,
        path_var: url,
        content: content,
        eduheader: headers.find((x) => x.name === header)?.id || 1,
        oldTitle: oldTitle,
      });
      // console.log("this is from PostEdit", result);
      window.alert("Post edited");
      await getContents();
      if (oldurl !== url) navigate("/tbadmin/post/"+url.split('/').pop()||"");
      axios
        .get<IEduHeader[]>(Constants.EDU_CONTENT_HEADER)
        .then((res) => {
          setHeaders(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      window.alert(`error in editing post: ${error}`);
    }
  };

  return (
    <div className="editContainer">
      <div className="headerTitle">
        <h3>Edit Post Editor/Preview</h3>
      </div>
      <div className="editorpreview">
        <div className="editor">
          <form onSubmit={onEditHandler}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="grp">Group:</label>
                  </td>
                  <td>
                    <select
                      name="grp"
                      id="grp"
                      value={header}
                      onChange={(e) => setHeader(e.target.value)}
                    >
                      {headers.map((h) => (
                        <option key={h.id} value={h.name}>
                          {h.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="urlpic">Pic Url:</label>
                  </td>
                  <td>
                    <input
                      id="urlpic"
                      type="text"
                      value={picUrl}
                      onChange={(e) => setPicUrl(e.target.value)}
                      size={30}
                      minLength={3}
                      placeholder="/dental_icons/dentalFFI.png"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="url">Url:</label>
                  </td>
                  <td>
                    <input
                      id="url"
                      type="text"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      size={30}
                      minLength={3}
                      placeholder="/education/title"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="title">Title:</label>
                  </td>
                  <td>
                    <input
                      id="title"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      size={30}
                      minLength={3}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <Textarea
              minRows={30}
              placeholder="Minimum 3 rows"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <button type="submit">Edit</button>
          </form>
        </div>
        <div className="preview">
          {parse(
            `<h1 className="eduTemplateH1">${title}</h1>` + content,
            options
          ) || ""}
        </div>
      </div>
    </div>
  );
};

export default PostEditor;
