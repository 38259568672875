import axios from "axios";
import { Constants } from "../../../Constants";
import store from "../../../store/indexStore";
import { adminSlice } from "../../../store/admin";
import { redirect } from "react-router-dom";

interface IVerificationResult {
  result: string;
  email?: string;
  role?: string;
}

// check for jwt
export const verification = async () => {
  const token = localStorage.getItem("tbau");
  if (!token) return redirect("/tbadmin/login");

  try {
    const response = await axios.post<IVerificationResult>(
      Constants.URL_ADMINVERIFY,
      {
        token,
      }
    );
    const { email, role } = response.data;
    store.dispatch(
      adminSlice.actions.setToken({ loginNick: email, role, isLoggedIn: true })
    );
    return null;
  } catch (error) {
    //401 unauthorised
    localStorage.removeItem("tbau");
    store.dispatch(adminSlice.actions.setToken({ isLoggedIn: false }));
    console.log("invalid token")
    return redirect("/tbadmin/login");
  }
};

export const loginAuthChecker = async()=>{
  const token = localStorage.getItem("tbau");
  if (!token) return null;

  try {
    const response = await axios.post<{ result: IVerificationResult }>(
      Constants.URL_ADMINVERIFY,
      {
        token,
      }
    );
    const { email, role } = response.data.result;
    store.dispatch(
      adminSlice.actions.setToken({ loginNick: email, role, isLoggedIn: true })
    );
    return redirect('/tbadmin/dashboard');
  } catch (error) {
    //401 unauthorised
    localStorage.removeItem("tbau");
    store.dispatch(adminSlice.actions.setToken({ isLoggedIn: false }));
    return null;
  }

}