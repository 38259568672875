import React from "react";
import { Button } from "@mui/material";

const startBtn = (props) => {
  // console.log("this is props:  ", props);
  const clickhandler = () => {
    props.state.node = 5;
    props.actions.clientSelectionHandler(props.bName, 5);
  };

  return (
    <Button
      variant="outlined"
      sx={{ borderRadius: 28, cursor: "pointer" }}
      onClick={clickhandler}
    >
      {props.bName}
    </Button>
  );
};

export default startBtn;
